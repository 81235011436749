import Header from "../comp/Header";
import Sidebar from "../comp/Sidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import { khatab } from "../App";
import { useLocation } from "react-router-dom";
import MainBtn from "../comp/MainBtn";

function ShowKhatab() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 flex gap-5";
  }, []);

  const location = useLocation().pathname;
  const videosId = khatab[location.replace(/\/\w+\//g, "").replace("/", "")];
  const [videos, setVideos] = useState([]);
  let str = "";

  useEffect(function () {
    videosId.forEach((e) => (str += `&id=${e}`));
    axios
      .get(
        `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyCaPIf4nGtiJOQ2pBDo7Jjy9kR2e1DsTaU&part=snippet${str}`
      )
      .then((res) => {
        let arr = [];
        res.data.items.forEach((ele) => {
          arr.push({
            id: ele.id,
            title: ele.snippet.title,
            img: ele.snippet.thumbnails.maxres
              ? ele.snippet.thumbnails.maxres.url
              : ele.snippet.thumbnails.standard.url,
          });
        });
        setVideos(arr);
      });
  }, []);

  return (
    <div className="w-full rounded-lg center-khatab-boxs grid gap-6">
      {videos.length !== 0 &&
        videos.map(function (e, i) {
          return (
            <div
              key={i}
              className="bg-white rounded-xl p-4 flex flex-col gap-5 justify-between"
            >
              <img src={e.img} alt="" className="rounded-xl" />
              <p className="text-xl font-medium text-center">{e.title}</p>
              <div className="flex gap-2 justify-between flex-wrap">
                <MainBtn href={`${location}/${e.id}`} text="المشاهدة" />
                <MainBtn
                  href={`https://www.youtube.com/watch?v=${e.id}`}
                  text="المشاهدة علي اليوتيوب"
                  target="_blank"
                />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ShowKhatab;
