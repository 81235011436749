import axios from "axios";
import { useEffect, useState } from "react";
import Choose from "./Choose";

function ChooseSurah() {
  let [surah, setSurah] = useState([]);

  useEffect(function () {
    axios
      .get("https://api.alquran.cloud/v1/surah")
      .then((res) => setSurah(res.data.data));
  }, []);

  return (
    surah.length !== 0 &&
    surah.map(function (e) {
      return (
        <Choose
          key={e.number}
          id={e.number}
          name={e.name}
          des={`${e.revelationType === "Meccan" ? "مكية" : "مدينية"} آياتها ${
            e.numberOfAyahs
          }`}
          href={`#${e.number}`}
        />
      );
    })
  );
}

export default ChooseSurah;
