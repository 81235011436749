import { useEffect } from "react";
import Listen from "../comp/Listen";

function ChooseSurah() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 h-screen flex gap-5";
  }, []);

  return (
    <div className="overflow-scroll w-full h-full p-1">
      <Listen />
    </div>
  );
}

export default ChooseSurah;
