import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./css/index.css";
import "./css/all.min.css";

import Error from "./pages/404";
import Home from "./pages/home";
import Quran from "./pages/quran";
import Hadis from "./pages/hadis";
import Books from "./pages/books";
import Azkar from "./pages/azkar";
import Pray from "./pages/pray";
import QuranRead from "./pages/quranRead";
import QuranListen from "./pages/quranListen";
import ChooseSurah from "./pages/chooseSurah";
import Audio from "./pages/audio";
import Contact from "./pages/contact";
import Ques from "./pages/ques";
import QuranChs from "./pages/quranChs";
import Surah from "./pages/surah";
import Khatab from "./pages/khatab";
import ShowKhatab from "./pages/showKhatab";
import ListenKhatab from "./pages/listenKhatab";
import Developers from "./pages/developers";
import Layout from "./comp/Layout";
import IslamicTimes from "./pages/islamicTimes";
// import Support from "./pages/support";

let fullArr = [];
for (let i = 1; i <= 114; i++) fullArr.push(i);

let hazza = [
  1, 13, 14, 15, 18, 19, 25, 29, 30, 31, 36, 37, 38, 39, 40, 24, 44, 47, 49, 50,
];
for (let i = 50; i <= 57; i++) hazza.push(i);
for (let i = 67; i <= 114; i++) hazza.push(i);

let ghamdi = [
  1, 6, 9, 14, 21, 25, 30, 42, 50, 52, 53, 54, 58, 59, 60, 61, 65, 47, 49, 50,
  77, 85, 86, 88, 91, 93,
];
for (let i = 65; i <= 71; i++) ghamdi.push(i);

let tlb = [1, 25, 34, 38, 39, 44, 45, 46, 47, 70];
for (let i = 55; i <= 61; i++) tlb.push(i);
for (let i = 72; i <= 86; i++) tlb.push(i);

let salman = [1, 2, 36, 46, 56, 58, 59];
for (let i = 61; i <= 114; i++) salman.push(i);
let obj = {
  qtm: [fullArr, 6],
  abkr: [fullArr, 6],
  minsh: [fullArr, 6],
  aloosi: [fullArr, 6],
  balilah: [fullArr, 6],
  ghamdi: [ghamdi, 6],
  shur: [fullArr, 7],
  frs_a: [fullArr, 8],
  sds: [fullArr, 8],
  lhdan: [fullArr, 8],
  afs: [fullArr, 8],
  mustafa: [fullArr, 8],
  ayyub: [fullArr, 8],
  hthfi: [fullArr, 9],
  tlb: [tlb, 9],
  jleel: [fullArr, 10],
  ajm: [fullArr, 10],
  hatem: [fullArr, 11],
  yasser: [fullArr, 11],
  a_jbr: [fullArr, 11],
  hazza: [hazza, 11],
  salman: [salman, 11],
  maher: [fullArr, 12],
  kyat: [fullArr, 12],
  basit_mjwd: [fullArr, 13],
  jhn: [fullArr, 13],
  mansor: [fullArr, 14],
  nufais: [fullArr, 16],
};
let khatab = {
  hatem: [
    "AAWMX6hcDYc",
    "xI5hqHXAy2k",
    "2C9dAyef6U4",
    "gge6IkP6MXs",
    "o7GDvUvCcxM",
  ],
  othmanalkamees: ["DfDPxOLHkfk", "T9yIZ_KT9aA", "2QVbiLrZsNA", "jj7UrHUrPnQ"],
  yasser: [
    "2i6FrtGUebA",
    "4nbTOGAt1Aw",
    "dMQKTjJnvSc",
    "pLnCUoe8WHk",
    "4kAXEns9gqM",
  ],
  qtm: [
    "7HQw99_id40",
    "BfN5Cd2PNTE",
    "ENSvThovwrU",
    "J-qE6jUHDzE",
    "okwoHV79rJo",
  ],
  sds: [
    "p8Sslyt-rsA",
    "G3VZDcbrr0s",
    "NXYGi99gkAI",
    "_Qo4QOTvNUw",
    "-2Cj3Ruw8vA",
  ],
  maher: ["GBxYTSibk8c", "btEie7v0bqA", "jq0gqmbq-to", "Uj7Fiq9NXZ4"],
  ibnhomaid: [
    "r-xrMUNWHY4",
    "Dy_bVjg8Bes",
    "QC5a30EWpU0",
    "U6VmKZaGqJk",
    "rf2UbLzMhKA",
  ],
};
function App() {
  const text = [
    {
      path: "",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/islamicTimes",
          element: <IslamicTimes />,
        },
        {
          path: "/quran",
          element: <Quran />,
        },
        {
          path: "/quran/chsSura",
          element: <QuranChs />,
        },
        {
          path: "/quran/read",
          element: <QuranRead />,
        },
        {
          path: "/quran/listen",
          element: <QuranListen />,
        },
        {
          path: "/khatab",
          element: <Khatab />,
        },
        {
          path: "/hadis",
          element: <Hadis />,
        },
        {
          path: "/books",
          element: <Books />,
        },
        {
          path: "/azkar",
          element: <Azkar />,
        },
        {
          path: "/pray",
          element: <Pray />,
        },
        {
          path: "/ques",
          element: <Ques />,
        },
        {
          path: "/developers",
          element: <Developers />,
        },
        // {
        //   path: "/support",
        //   element: <Support />,
        // },
        {
          path: "/contact",
          element: <Contact />,
        },
      ],
    },
    {
      path: "*",
      element: <Error />,
    },
  ];

  for (let i = 1; i <= 114; i++)
    text[0].children.push({ path: `/quran/chsSura/${i}`, element: <Surah /> });

  Object.keys(obj).forEach((e) => {
    text[0].children.push({
      path: `/quran/listen/${e}`,
      element: <ChooseSurah />,
    });

    obj[e][0].forEach((ele) =>
      text[0].children.push({
        path: `/quran/listen/${e}/${ele}`,
        element: <Audio />,
      })
    );
  });
  Object.keys(khatab).forEach((e) => {
    text[0].children.push({
      path: `/khatab/${e}`,
      element: <ShowKhatab />,
    });

    khatab[e].forEach((el) =>
      text[0].children.push({
        path: `/khatab/${e}/${el}`,
        element: <ListenKhatab id={el} name={e} />,
      })
    );
  });

  const router = createBrowserRouter(text);
  return <RouterProvider router={router} />;
}

export { obj, khatab };
export default App;
