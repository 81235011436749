import Choose from "./Choose";

let chooseArr = [
  {
    name: "أحدايث الصلاة",
    href: "/hadis#prayer",
  },
  {
    name: "العشرة الأوائل و الحج",
    href: "/hadis#haj",
  },
  {
    name: "أحاديث الصوم و رمضان",
    href: "/hadis#ramadan",
  },
];

function Choosehadis() {
  return chooseArr.map((e, i) => (
    <Choose key={i} id={i + 1} name={e.name} href={e.href} />
  ));
}

export default Choosehadis;
