import { useEffect } from "react";

let ques = [
  {
    id: 1,
    ques: "على أي شيء يطلق اسم الأخشبان؟",
    answar: "اسم لجبلين بمكة.",
  },
  {
    id: 2,
    ques: "من هم المغضوب عليهم؟ ولماذ؟",
    answar: (
      <>
        واليهود، لأنهم علموا الحق فتركوه وحادوا عنه على علم فاستحقوا غضب الله و
        الدليل علي ذلك قو الله تعالي <br />
        ("فَبَاءُوا بِغَضَبٍ عَلَى غَضَبٍ")[البقرة - 90]
      </>
    ),
  },
  {
    id: 3,
    ques: "من هم الضالين؟ ولماذ؟",
    answar: (
      <>
        النصارى لأنهم حادوا عن الحق جهلاً فكانوا على ضلال بين في شأن عيسى عليه
        السلام و الدليل قول الله تعالي <br />
        ("وَلَا تَتَّبِعُوا أَهْوَاءَ قَوْمٍ قَدْ ضَلُّوا مِنْ قَبْلُ
        وَأَضَلُّوا كَثِيرًا")[المائدة - 77] .
      </>
    ),
  },
  {
    id: 4,
    ques: "ما حكمة الله من خلق النجوم؟",
    answar: (
      <>
        خلقها لثلاث:
        <br />
        1- لنهتدي بها في البر و البحر والدليل علي ذلك قوله عز وجعل
        <br />
        (" وَهُوَ الَّذِي جَعَلَ لَكُمُ النُّجُومَ لِتَهْتَدُوا بِهَا فِي
        ظُلُمَاتِ الْبَرِّ وَالْبَحْرِ قَدْ فَصَّلْنَا الْآيَاتِ لِقَوْمٍ
        يَعْلَمُونَ)[الأنعام - 97]
        <br />
        2- زينة للسماء
        <br />
        3- رجوماً للشياطين و الدليل عليهما قوله تعالي:
        <br />
        (" وَلَقَدْ زَيَّنَّا السَّمَاءَ الدُّنْيَا بِمَصَابِيحَ وَجَعَلْنَاهَا
        رُجُومًا لِلشَّيَاطِينِ وَأَعْتَدْنَا لَهُمْ عَذَابَ السَّعِيرِ")[الملك
        - 5]
        <br />
      </>
    ),
  },
  {
    id: 5,
    ques: "على من يطلق المصدود؟",
    answar: "الذي يمنع من الحج.",
  },
  {
    id: 6,
    ques: "من النبي الذي كان يسمى بشرى؟",
    answar: "ذا الكفل.",
  },
  {
    id: 7,
    ques: "من الذي عدلت شهادته شهادة الرجلين؟",
    answar: "خزيمة بن ثابت رضي الله عنه.",
  },
  {
    id: 8,
    ques: "من آخر من توفى من الصحابة؟",
    answar: "أنس بن مالك.",
  },
  {
    id: 9,
    ques: "كم عدة المرأة المطلقة؟",
    answar: "ثلاث قروء أي ثلاث حيضات.",
  },
  {
    id: 10,
    ques: "ما اسم خازن الجنة؟",
    answar: "رضوان.",
  },
  {
    id: 11,
    ques: "ما اسم خازن النار؟",
    answar: "مالك.",
  },
  {
    id: 12,
    ques: "ليلة الإسراء إمتطى النبي محمد صلى الله عليه وسلم دابة مجنحة، ما أسمه؟",
    answar: "البراق.",
  },
  {
    id: 13,
    ques: "ما هو عدد مرات التي ورد فيها ذكر النار في المصحف الشريف؟",
    answar: "126مرة.",
  },
  {
    id: 14,
    ques: "عشرة من المسلمين بشروا بالجنة من كان آخر من مات منهم؟",
    answar: "سعد بن أبي وقاص.",
  },
  {
    id: 15,
    ques: "ماهي العملة الإسلامية الأولى؟",
    answar: "الدينار الذهبي.",
  },
  {
    id: 16,
    ques: "في أي من أيام الأسبوع خلق الله آدم (عليه السلام )؟",
    answar: "يوم الجمعة.",
  },
  {
    id: 17,
    ques: "من هم الثلاثة الذين تشتاق الجنة إليهم؟",
    answar: "علي بن ابي طالب. عمار بن ياسر ـ سلمان الفارسي رضي الله عنهم.",
  },
  {
    id: 18,
    ques: "يوم ترجف الراجفة.. ما المقصود بالراجفة؟",
    answar: "النفخة الأولى التي يموت بها جميع الخلائق.",
  },
  {
    id: 19,
    ques: "تتبعها الرادفة ما المقصود بالرادفة؟",
    answar: "النفخة الثانية التي يكون عندها البعث.",
  },
  {
    id: 20,
    ques: "ما الفرق بين سندس وإستبرق؟",
    answar:
      "السندس: رقيق الديباج أو الحرير.. الإستبرق: الغليظ من الديباج أو الحرير.",
  },
  {
    id: 21,
    ques: "ما أطول كلمة في وردت القرآن الكريم ؟",
    answar: "كلمة “فَأَسْقَيْنَاكُمُوهُ”، وقيل أنها “فَسَيَكْفِيكَهُمُ”.",
  },
  {
    id: 22,
    ques: "كم سجدة يتضمنها القرآن الكريم؟",
    answar: "ثلاثة عشر سجدة.",
  },
  {
    id: 23,
    ques: "من هو النبي الكريم الذي أُرسل إلى قوم عاد؟",
    answar: "النبي الكريم هو سيدنا هود عليه السلام.",
  },
  {
    id: 24,
    ques: "صحابي من الصحابة لقب بساعي الرسول صلى الله عليه وسلم؟",
    answar: "هو الصحابي عمرو بن أمية الضمري رضي الله عنه وأرضاه.",
  },
  {
    id: 25,
    ques: "ما هو اسم زوجة فرعون؟",
    answar: "اسمها اسيا عليها السلام وكانت مؤمنة بالله تعالى.",
  },
];

function Ques() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 h-screen flex gap-5";
  }, []);
  return (
    <div className="overflow-scroll w-full h-full p-1">
      <table className="quran-table bg-white rounded-lg p-2 w-full">
        <thead>
          <tr className="border-2 border-transparent border-b-border">
            <td className="text-2xl text-main text-center font-bold p-3">
              السؤال
            </td>
            <td className="text-2xl text-main text-center font-bold p-3">
              الجواب
            </td>
          </tr>
        </thead>
        <tbody>
          {ques.map(function (e) {
            return (
              <tr key={e.id} className="h-20 duration-300">
                <td className="text-2xl text-center text-main font-semibold w-96">
                  {e.ques}
                </td>
                <td className="text-2xl text-center font-medium p-3 leading-loose">
                  {e.answar}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Ques;
