import { NavLink } from "react-router-dom";
import quran from "../imgs/quran.png";
import { useEffect } from "react";

function Quran() {
  useEffect(() => {
    document.getElementById("father").className =
      "relative w-full py-4 px-5 pt-24 min-h-screen flex items-center gap-5";
  }, []);
  return (
    <div className="w-full flex items-center justify-center gap-10 flex-wrap">
      <div className="bg-white rounded-lg p-5 flex flex-col gap-7 max-w-[520px] shadow-box">
        <div className="relative overflow-hidden rounded-lg before:absolute before:bottom-0 before:right-0 before:w-full before:h-full before:opacity-60 before:bg-gradient-to-t before:from-main">
          <img
            src="https://peco-2.github.io/islamic/quran/quran.jpg"
            alt=""
            className="object-cover"
          />
        </div>
        <p className="text-xl font-medium text-center">
          ("من استمع إلى آية من كتاب الله كتب له حسنة مضاعفة، ومن تلاها كانت له
          نوراً يوم القيامة")
        </p>
        <NavLink
          to="/quran/listen"
          className="flex items-center justify-center gap-3 bg-sidebar rounded-xl p-2 text-white text-xl md:text-2xl font-semibold"
        >
          الإستماع إلي القرآن
          <i className="fa-solid fa-headphones clear-left text-3xl"></i>
        </NavLink>
      </div>
      <div className="bg-white rounded-lg p-5 flex flex-col gap-7 max-w-[520px] shadow-box">
        <div className="relative overflow-hidden rounded-lg before:absolute before:bottom-0 before:right-0 before:w-full before:h-full before:opacity-60 before:bg-gradient-to-t before:from-main">
          <img
            src="https://peco-2.github.io/islamic/quran/quran-2.jpg"
            alt=""
            className="object-cover h-[349px]"
          />
        </div>
        <p className="text-xl font-medium text-center">
          ("عليك بذكر الله وتلاوة القرآن فانه روحك في السماء وذكرك في الأرض")
        </p>
        <NavLink
          to="/quran/chsSura"
          className="flex items-center justify-center gap-3 bg-sidebar rounded-xl p-2 text-white text-xl md:text-2xl font-semibold"
        >
          قراءة القرآن
          <img className="w-8 h-8 brightness-[40]" src={quran} alt="" />
        </NavLink>
      </div>
    </div>
  );
}
export default Quran;
