import { useEffect } from "react";
import KhatabBoxs from "../comp/KhatabBoxs";

function Khatab() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 flex gap-5";
  }, []);
  return (
    <>
      <div className="w-full rounded-lg center-khatab grid gap-6">
        <KhatabBoxs />
      </div>
    </>
  );
}

export default Khatab;
