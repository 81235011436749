import BookBox from "./BookBox";

import elbokare from "../books/العلوم الشرعية/صحيح البخاري.pdf";
import muslim from "../books/العلوم الشرعية/صحيح مسلم.pdf";
import alnasayiyi from "../books/العلوم الشرعية/سنن النسائي.pdf";
import startAndEnd from "../books/التاريخ/البداية و النهاية إبن كثيير.pdf";
import altawhid from "../books/العقيدة/التوحيد.pdf";
import quran from "../books/العقيدة/القرآن الكريم.pdf";
import alsiyratAlbasitah from "../books/التاريخ/السيرة النوبية المبسطة.pdf";
import qisasAlsahaba from "../books/التاريخ/قصص الصحابة للأطفال.PDF";
import altajwid from "../books/العلوم الشرعية/تجويد.pdf";
import quranMsg from "../books/العقيدة/رسائل من القرآن.pdf";
import platform from "../books/الطفل المسلم/منهاج المسلم.pdf";

let typeArr = ["All", "Legitimacy", "History", "Eaqida", "Child"];

let [All, Legitimacy, History, Eaqida, Child] = typeArr;
const src = "https://peco-2.github.io/islamic/books/";

let boxsArr = [
  {
    img: `${src}quran.webp`,
    title: "القرآن الكريم",
    author: "كلام الله",
    file: quran,
    type: [All, Eaqida],
  },
  {
    img: `${src}elbokare.webp`,
    title: "صحيح البخاري",
    author: "محمد بن اسماعيل البخاري",
    file: elbokare,
    type: [All, Legitimacy],
  },
  {
    img: `${src}muslim.jpg`,
    title: "صحيح مسلم",
    author: "مسلم بن الحجاج",
    file: muslim,
    type: [All, Legitimacy],
  },
  {
    img: `${src}سنن النسائي.jpg`,
    title: "سنن النسائي",
    author: "أحمد بن شعيب النسائي",
    file: alnasayiyi,
    type: [All, Legitimacy],
  },
  {
    img: `${src}startAndEnd.png`,
    title: "البداية و النهاية",
    author: "ابن كثير الدمشقي",
    file: startAndEnd,
    type: [All, History],
  },
  {
    img: `${src}altawhid.webp`,
    title: "كتاب التوحيد",
    author: "محمد بن عبدالوهاب",
    file: altawhid,
    type: [All, Eaqida],
  },
  {
    img: `${src}alsiyrat-albasitah.png`,
    title: "السيرة النوبية للأطفال",
    author: "مسعد حسن محمد",
    file: alsiyratAlbasitah,
    type: [All, History],
  },
  {
    img: `${src}qisas-alsahaba.png`,
    title: "قصص الصحابة للأطفال",
    author: "مسعد حسن محمد",
    file: qisasAlsahaba,
    type: [All, History],
  },
  {
    img: `${src}altajwid.png`,
    title: "الملخص المفيد في التجويد",
    author: "محمد أحمد معبد",
    file: altajwid,
    type: [All, Legitimacy],
  },
  {
    img: `${src}quranMsg.webp`,
    title: "رسائل من القرآن",
    author: "أدهم شرقاوي",
    file: quranMsg,
    type: [All, Eaqida],
  },
  {
    img: `${src}platform.webp`,
    title: "منهاج المسلم ",
    author: "أبو بكر جابر الجزائري",
    file: platform,
    type: [All, Child],
  },
];

function BookBoxs() {
  return boxsArr.map(function (e, i) {
    return (
      <BookBox
        key={i}
        img={e.img}
        title={e.title}
        author={e.author}
        href={e.file}
        type={e.type.join(" ")}
      />
    );
  });
}

export default BookBoxs;
