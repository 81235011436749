import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";

import "swiper/css/navigation";
import "swiper/css";
import { useEffect, useState } from "react";

const src = "https://peco-2.github.io/islamic/azkar/";

function Azkar() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 h-screen flex gap-5";
  }, []);

  let azkars = [
    {
      id: 1,
      title: "أذكار الصباح",
      length: "0",
      img: `${src}sun.png`,
      content: [
        {
          id: 1,
          title: "أية الكرسي",
          num: "مره واحدة",
          text: "اللّهُ لاَ إِلَـهَ إِلاَّ هُوَ الْحَيُّ الْقَيُّومُ لاَ تَأْخُذُهُ سِنَةٌ وَلاَ نَوْمٌ لَّهُ مَا فِي السَّمَاوَاتِ وَمَا فِي الأَرْضِ مَن ذَا الَّذِي يَشْفَعُ عِنْدَهُ إِلاَّ بِإِذْنِهِ يَعْلَمُ مَا بَيْنَ أَيْدِيهِمْ وَمَا خَلْفَهُمْ وَلاَ يُحِيطُونَ بِشَيْءٍ مِّنْ عِلْمِهِ إِلاَّ بِمَا شَاء وَسِعَ كُرْسِيُّهُ السَّمَاوَاتِ وَالأَرْضَ وَلاَ يَؤُودُهُ حِفْظُهُمَا وَهُوَ الْعَلِيُّ الْعَظِيمُ",
        },
        {
          id: 2,
          title: "قراءة سورة الإخلاص",
          num: "ثلاثة مرات",
          text: "قُلْ هُوَ اللَّهُ أَحَدٌ * اللَّهُ الصَّمَدُ * لَمْ يَلِدْ وَلَمْ يُولَدْ * وَلَمْ يَكُن لَّهُ كُفُوًا أَحَدٌ",
        },
        {
          id: 3,
          title: "قراءة سورة الفلق",
          num: "ثلاثة مرات",
          text: `قُلْ أَعُوذُ بِرَبِّ الْفَلَقِ * مِن شَرِّ مَا خَلَقَ * وَمِن شَرِّ غَاسِقٍ إِذَا وَقَبَ * وَمِن شَرِّ النَّفَّاثَاتِ فِي الْعُقَدِ * وَمِن شَرِّ حَاسِدٍ إِذَا حَسَد`,
        },
        {
          id: 4,
          title: "قراءة سورة الناس",
          num: "ثلاثة مرات",
          text: `قُلْ أَعُوذُ بِرَبِّ النَّاسِ* مَلِكِ النَّاسِ* إِلَٰهِ النَّاسِ* مِن شَرِّ الْوَسْوَاسِ الْخَنَّاسِ* الَّذِي يُوَسْوِسُ فِي صُدُورِ النَّاسِ* مِنَ الْجِنَّةِ وَالنَّاسِ`,
        },
        {
          id: 5,
          title: "أَصْـبَحْنا وَأَصْـبَحَ المُـلْكُ لله",
          num: "مره واحدة",
          text: "سأَصْـبَحْنا وَأَصْـبَحَ المُـلْكُ لله وَالحَمدُ لله . لا إلهَ إلاّ اللّهُ وَحدَهُ لا شَريكَ لهُ . لهُ المُـلكُ ولهُ الحَمْـد . وهُوَ على كلّ شَيءٍ قدير . رَبِّ أسْـأَلُـكَ خَـيرَ ما في هـذا اليوم وَخَـيرَ ما بَعْـدَه . وَأَعـوذُ بِكَ مِنْ شَـرِّ ما في هـذا اليوم رَبِّ أَعُوذُ بكَ مِنَ الكَسَلِ وَسُوءِ الكِبَرِ . رَبِّ أَعُوذُ بكَ مِن عَذَابٍ في النَّارِ وَعَذَابٍ في القَبْرِ",
        },
        {
          id: 6,
          title: "اللَّهمَّ أنتَ ربِّي لا إلَهَ إلَّا أنتَ",
          num: "مره واحدة",
          text: "اللَّهمَّ أنتَ ربِّي لا إلَهَ إلَّا أنتَ . خلقتني وأنا عبدُكَ وأنا على عَهدِكَ ووعدِكَ ما استطعتُ . أعوذُ بِكَ من شرِّ ما صنعتُ . أبوءُ لك بنعمتِكَ عليَّ وأبوءُ بذنبي . فاغفر لي إنَّهُ لا يغفرُ الذُّنوبَ إلَّا أنتَ",
        },
        {
          id: 7,
          title: "سبحانَ اللَّهِ وبحمدِهِ",
          num: "مائة واحدة",
          text: "مَن قالَ حينَ يصبحُ وحينَ يُمسي : سبحانَ اللَّهِ وبحمدِهِ مائةَ مرَّةٍ : لم يأتِ أحدٌ يومَ القيامةِ بأفضلَ ممَّا جاءَ بِهِ  . إلَّا أحدٌ قالَ مثلَ ما قالَ  . أو زادَ علَيهِ",
        },
      ],
    },
    {
      id: 2,
      title: "أذكار المساء",
      length: "0",
      img: `${src}moon.png`,
      content: [
        {
          id: 1,
          title: "أية الكرسي",
          num: "مره واحدة",
          text: "اللّهُ لاَ إِلَـهَ إِلاَّ هُوَ الْحَيُّ الْقَيُّومُ لاَ تَأْخُذُهُ سِنَةٌ وَلاَ نَوْمٌ لَّهُ مَا فِي السَّمَاوَاتِ وَمَا فِي الأَرْضِ مَن ذَا الَّذِي يَشْفَعُ عِنْدَهُ إِلاَّ بِإِذْنِهِ يَعْلَمُ مَا بَيْنَ أَيْدِيهِمْ وَمَا خَلْفَهُمْ وَلاَ يُحِيطُونَ بِشَيْءٍ مِّنْ عِلْمِهِ إِلاَّ بِمَا شَاء وَسِعَ كُرْسِيُّهُ السَّمَاوَاتِ وَالأَرْضَ وَلاَ يَؤُودُهُ حِفْظُهُمَا وَهُوَ الْعَلِيُّ الْعَظِيمُ",
        },
        {
          id: 2,
          title: "قراءة سورة الإخلاص",
          num: "ثلاثة مرات",
          text: "قُلْ هُوَ اللَّهُ أَحَدٌ * اللَّهُ الصَّمَدُ * لَمْ يَلِدْ وَلَمْ يُولَدْ * وَلَمْ يَكُن لَّهُ كُفُوًا أَحَدٌ",
        },
        {
          id: 3,
          title: "قراءة سورة الفلق",
          num: "ثلاثة مرات",
          text: `قُلْ أَعُوذُ بِرَبِّ الْفَلَقِ * مِن شَرِّ مَا خَلَقَ * وَمِن شَرِّ غَاسِقٍ إِذَا وَقَبَ * وَمِن شَرِّ النَّفَّاثَاتِ فِي الْعُقَدِ * وَمِن شَرِّ حَاسِدٍ إِذَا حَسَد`,
        },
        {
          id: 4,
          title: "قراءة سورة الناس",
          num: "ثلاثة مرات",
          text: `قُلْ أَعُوذُ بِرَبِّ النَّاسِ* مَلِكِ النَّاسِ* إِلَٰهِ النَّاسِ* مِن شَرِّ الْوَسْوَاسِ الْخَنَّاسِ* الَّذِي يُوَسْوِسُ فِي صُدُورِ النَّاسِ* مِنَ الْجِنَّةِ وَالنَّاسِ`,
        },
        {
          id: 5,
          title: "أمسينا وَأَصْـبَحَ المُـلْكُ لله",
          num: "مره واحدة",
          text: "أَمْسَيْنَا وَأَمْسَى المُلْكُ لِلَّهِ . وَالْحَمْدُ لِلَّهِ لا إلَهَ إلَّا اللَّهُ . وَحْدَهُ لا شَرِيكَ له له المُلْكُ وَلَهُ الحَمْدُ وَهو علَى كُلِّ شيءٍ قَدِيرٌ . رَبِّ أَسْأَلُكَ خَيْرَ ما في هذِه اللَّيْلَةِ وَخَيْرَ ما بَعْدَهَا . وَأَعُوذُ بكَ مِن شَرِّ ما في هذِه اللَّيْلَةِ وَشَرِّ ما بَعْدَهَا . رَبِّ أَعُوذُ بكَ مِنَ الكَسَلِ وَسُوءِ الكِبَرِ . رَبِّ أَعُوذُ بكَ مِن عَذَابٍ في النَّارِ وَعَذَابٍ في القَبْرِ",
        },
        {
          id: 6,
          title: "اللَّهمَّ أنتَ ربِّي لا إلَهَ إلَّا أنتَ",
          num: "مره واحدة",
          text: "اللَّهمَّ أنتَ ربِّي لا إلَهَ إلَّا أنتَ . خلقتني وأنا عبدُكَ وأنا على عَهدِكَ ووعدِكَ ما استطعتُ . أعوذُ بِكَ من شرِّ ما صنعتُ . أبوءُ لك بنعمتِكَ عليَّ وأبوءُ بذنبي . فاغفر لي إنَّهُ لا يغفرُ الذُّنوبَ إلَّا أنتَ",
        },
        {
          id: 7,
          title: "سبحانَ اللَّهِ وبحمدِهِ",
          num: "مائة واحدة",
          text: "مَن قالَ حينَ يصبحُ وحينَ يُمسي : سبحانَ اللَّهِ وبحمدِهِ مائةَ مرَّةٍ : لم يأتِ أحدٌ يومَ القيامةِ بأفضلَ ممَّا جاءَ بِهِ  . إلَّا أحدٌ قالَ مثلَ ما قالَ  . أو زادَ علَيهِ",
        },
      ],
    },
    {
      id: 3,
      title: "أذكار الاستيقاظ",
      length: "0",
      img: `${src}wakeUp.png`,
      content: [
        {
          id: 1,
          title: "",
          num: "مره واحدة",
          text: "الحمد لله الذي أحيانا بعد ما أماتنا . وإليه النشور",
        },
        {
          id: 2,
          title: "",
          num: "مره واحدة",
          text: "لا إله إلا الله وحده لا شريك له . له الملك وله الحمد . وهو على كل شيء قدير . سبحان الله . والحمد لله . ولا إله إلا الله . والله أكبر . ولا حول ولا قوة إلا بالله العلي العظيم . رب اغفر لي",
        },
        {
          id: 3,
          title: "",
          num: "مره واحدة",
          text: "الحمدُ للهِ الذي عافانِى في جَسَدِي  . ورَدَّ عَلَيَّ رُوحِي  . وأَذِنَ لي بذِكْرِه",
        },
      ],
    },
    {
      id: 4,
      title: "أذكار النوم",
      length: "0",
      img: `${src}sleep.png`,
      content: [
        {
          id: 1,
          title: "",
          num: "مره واحدة",
          text: "اللَّهُمَّ باسْمِكَ أمُوتُ وأَحْيا",
        },
        {
          id: 2,
          title: "",
          num: "مره واحدة",
          text: "اللَّهمَّ أنتَ خلَقْتَ نفسي وأنتَ تتوفَّاها لك مماتُها ومحياها اللَّهمَّ إنْ توفَّيْتَها فاغفِرْ لها وإنْ أحيَيْتَها فاحفَظْها اللَّهمَّ إنِّي أسأَلُك العافيةَ",
        },
        {
          id: 3,
          title: "",
          num: "مره واحدة",
          text: "الحَمْدُ لِلَّهِ الذي أَطْعَمَنَا وَسَقَانَا . وَكَفَانَا وَآوَانَا . فَكَمْ مِمَّنْ لا كَافِيَ له وَلَا مُؤْوِيَ",
        },
        {
          id: 4,
          title: "",
          num: "مره واحدة",
          text: "لا إلَهَ إلَّا اللَّهُ وحْدَهُ لا شَرِيكَ له . له المُلْكُ وله الحَمْدُ . وهو علَى كُلِّ شيءٍ قَدِيرٌ . الحَمْدُ لِلَّهِ . وسُبْحَانَ اللَّهِ . ولَا إلَهَ إلَّا اللَّهُ . واللَّهُ أَكْبَرُ . ولَا حَوْلَ ولَا قُوَّةَ إلَّا باللَّهِ",
        },
        {
          id: 5,
          title: "",
          num: "مره واحدة",
          text: "سُبْحَانَكَ اللَّهُمَّ رَبِّي بكَ وَضَعْتُ جَنْبِي . وَبِكَ أَرْفَعُهُ . إنْ أَمْسَكْتَ نَفْسِي . فَاغْفِرْ لَهَا . وإنْ أَرْسَلْتَهَا فَاحْفَظْهَا بما تَحْفَظُ به عِبَادَكَ الصَّالِحِينَ",
        },
        {
          id: 6,
          title: "",
          num: "مره واحدة",
          text: "سُبْحَانَكَ اللَّهُمَّ رَبِّي بكَ وَضَعْتُ جَنْبِي . وَبِكَ أَرْفَعُهُ . إنْ أَمْسَكْتَ نَفْسِي . فَاغْفِرْ لَهَا . وإنْ أَرْسَلْتَهَا فَاحْفَظْهَا بما تَحْفَظُ به عِبَادَكَ الصَّالِحِينَ",
        },
      ],
    },
    {
      id: 5,
      title: "أذكار الصلاة",
      length: "0",
      img: `${src}mosque.png`,
      content: [
        {
          id: 1,
          title: "أسغفر الله أستغفر الله أستغفر الله",
          num: "مره واحدة",
          text: "أسغفر الله أستغفر الله أستغفر الله اللهمَّ أنتَ السَّلامُ . ومنك السَّلامُ . تبارَكْتَ يا ذا الجلالِ والإكرامِ",
        },
        {
          id: 2,
          title: "لا إلهَ إلَّا اللهُ",
          num: "مره واحدة",
          text: "لا إلهَ إلَّا اللهُ وحدَه لا شريكَ له . له المُلكُ . وله الحمدُ . وهو على كلِّ شيءٍ قديرٌ . اللهمَّ لا مانعَ لِما أعطَيْتَ . ولا مُعْطيَ لِما منَعْتَ . ولا ينفَعُ ذا الجَدِّ منك الجَدُّ",
        },
        {
          id: 3,
          title: "سبحان الله  . الحمدلله  . الله أكبر",
          num: "ثلاثة و ثلاثون واحدة",
          text: "سبحان الله ثلاثة و ثلاثون مره ثم الحمدلله ثلاثة و ثلاثون مره ثم الله أكبر ثلاثة وثلاثون مره",
        },
      ],
    },
    {
      id: 6,
      title: "أذكار المسجد",
      length: "0",
      img: `${src}mosque2.png`,
      content: [
        {
          id: 1,
          title: "الذَّهَابِ إلَى المَسْجِدِ",
          num: "مره واحده",
          text: "اللّهُـمَّ اجْعَـلْ في قَلْبـي نورا  . وَفي لِسـاني نورا . وَاجْعَـلْ في سَمْعي نورا . وَاجْعَـلْ في بَصَري نورا . وَاجْعَـلْ مِنْ خَلْفي نورا . وَمِنْ أَمامـي نورا . وَاجْعَـلْ مِنْ فَوْقـي نورا  . وَمِن تَحْتـي نورا .اللّهُـمَّ أَعْطِنـي نورا",
        },
        {
          id: 2,
          title: "دخُولِ المَسْجِدِ",
          num: "مره واحده",
          text: "أَعوذُ باللهِ العَظيـم وَبِوَجْهِـهِ الكَرِيـم وَسُلْطـانِه القَديـم مِنَ الشّيْـطانِ الرَّجـيم . بِسْمِ اللَّهِ . وَالصَّلاةُ وَالسَّلامُ عَلَى رَسُولِ الله . اللّهُـمَّ افْتَـحْ لي أَبْوابَ رَحْمَتـِك",
        },
        {
          id: 3,
          title: "الخروج من المَسْجِدِ",
          num: "مره واحده",
          text: "بِسْـمِ اللَّـهِ وَالصَّلاةُ وَالسَّلامُ عَلَى رَسُولِ اللَّهِ . اللَّهُمَّ إنِّي أَسْأَلُكَ مِنْ فَضْلِكَ . اللَّهُمَّ اعْصِمْنِي مِنَ الشَّيْطَانِ الرَّجِيم",
        },
      ],
    },
  ];
  let [content, setContent] = useState(azkars[0].content);
  let [azkarTitle, seAzkarTitle] = useState(azkars[0].title);
  return (
    <div className="h-full overflow-scroll relative w-full bg-white p-6 rounded-xl">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode]}
        className="mySwiper flex mb-6"
      >
        {azkars.map((e, i) => {
          return (
            <SwiperSlide
              key={e.id}
              className="main-btn2 max-w-[285px] overflow-hidden relative justify-center gap-2 flex-col cursor-pointer text-white rounded-xl p-3 font-bold"
              style={{
                height: "110px",
                display: "flex",
              }}
              onClick={() => {
                setContent(azkars[i].content);
                seAzkarTitle(azkars[i].title);
              }}
            >
              <img
                src={e.img}
                alt=""
                className="absolute -left-10 top-1/2 -translate-y-1/2"
                style={{ height: "calc(100% + 20px)" }}
              />
              <p className="text-3xl">{e.title}</p>
              <p className="text-2xl">{e.content.length} أذكار</p>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <hr className="border-[#e7e7e7]" />
      <div>
        <h2 className="text-[40px] leading-snug text-main text-center font-bold mt-5">
          {azkarTitle}
        </h2>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper flex mb-6"
        >
          {content.map((e) => {
            return (
              <SwiperSlide
                key={e.id}
                className="py-1 rounded-lg leading-loose lg:leading-loose text-3xl text-center"
              >
                <p
                  className="font-bold"
                  style={{ maxWidth: "calc(100vw - 80px)" }}
                >
                  {e.id} / {content.length}
                </p>
                <p
                  className="sura leading-loose text-4xl md:text-[42px]"
                  style={{ maxWidth: "calc(100vw - 80px)" }}
                >
                  {e.text}
                </p>
                <p
                  className="font-bold text-[#bbbabb]"
                  style={{ maxWidth: "calc(100vw - 80px)" }}
                >
                  {e.num}
                </p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default Azkar;
