import Header from "../comp/Header";
import pageError from "../imgs/pageError.png";

const Error = () => {
  return (
    <>
      <Header />
      <div className="w-screen h-screen flex items-center justify-center pt-20">
        <img src={pageError} alt="404 Error" className="imgError" />
      </div>
    </>
  );
};

export default Error;
