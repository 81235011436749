import { useEffect } from "react";
import { NavLink } from "react-router-dom";
// import axios from "axios";

function QuranChs() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 flex flex-col max-[890px]:flex-col gap-5";
  }, []);

  const quranSuras = [
    {
      revelationType: "Meccan",
      numberOfAyahs: 7,
      name: "سُورَةُ ٱلْفَاتِحَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 286,
      name: "سُورَةُ البَقَرَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 200,
      name: "سُورَةُ آلِ عِمۡرَانَ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 176,
      name: "سُورَةُ النِّسَاءِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 120,
      name: "سُورَةُ المَائـِدَةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 165,
      name: "سُورَةُ الأَنۡعَامِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 206,
      name: "سُورَةُ الأَعۡرَافِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 75,
      name: "سُورَةُ الأَنفَالِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 129,
      name: "سُورَةُ التَّوۡبَةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 109,
      name: "سُورَةُ يُونُسَ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 123,
      name: "سُورَةُ هُودٍ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 111,
      name: "سُورَةُ يُوسُفَ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 43,
      name: "سُورَةُ الرَّعۡدِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 52,
      name: "سُورَةُ إِبۡرَاهِيمَ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 99,
      name: "سُورَةُ الحِجۡرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 128,
      name: "سُورَةُ النَّحۡلِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 111,
      name: "سُورَةُ الإِسۡرَاءِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 110,
      name: "سُورَةُ الكَهۡفِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 98,
      name: "سُورَةُ مَرۡيَمَ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 135,
      name: "سُورَةُ طه",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 112,
      name: "سُورَةُ الأَنبِيَاءِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 78,
      name: "سُورَةُ الحَجِّ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 118,
      name: "سُورَةُ المُؤۡمِنُونَ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 64,
      name: "سُورَةُ النُّورِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 77,
      name: "سُورَةُ الفُرۡقَانِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 227,
      name: "سُورَةُ الشُّعَرَاءِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 93,
      name: "سُورَةُ النَّمۡلِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 88,
      name: "سُورَةُ القَصَصِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 69,
      name: "سُورَةُ العَنكَبُوتِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 60,
      name: "سُورَةُ الرُّومِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 34,
      name: "سُورَةُ لُقۡمَانَ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 30,
      name: "سُورَةُ السَّجۡدَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 73,
      name: "سُورَةُ الأَحۡزَابِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 54,
      name: "سُورَةُ سَبَإٍ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 45,
      name: "سُورَةُ فَاطِرٍ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 83,
      name: "سُورَةُ يسٓ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 182,
      name: "سُورَةُ الصَّافَّاتِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 88,
      name: "سُورَةُ صٓ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 75,
      name: "سُورَةُ الزُّمَرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 85,
      name: "سُورَةُ غَافِرٍ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 54,
      name: "سُورَةُ فُصِّلَتۡ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 53,
      name: "سُورَةُ الشُّورَىٰ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 89,
      name: "سُورَةُ الزُّخۡرُفِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 59,
      name: "سُورَةُ الدُّخَانِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 37,
      name: "سُورَةُ الجَاثِيَةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 35,
      name: "سُورَةُ الأَحۡقَافِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 38,
      name: "سُورَةُ مُحَمَّدٍ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 29,
      name: "سُورَةُ الفَتۡحِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 18,
      name: "سُورَةُ الحُجُرَاتِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 45,
      name: "سُورَةُ قٓ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 60,
      name: "سُورَةُ الذَّارِيَاتِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 49,
      name: "سُورَةُ الطُّورِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 62,
      name: "سُورَةُ النَّجۡمِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 55,
      name: "سُورَةُ القَمَرِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 78,
      name: "سُورَةُ الرَّحۡمَٰن",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 96,
      name: "سُورَةُ الوَاقِعَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 29,
      name: "سُورَةُ الحَدِيدِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 22,
      name: "سُورَةُ المُجَادلَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 24,
      name: "سُورَةُ الحَشۡرِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 13,
      name: "سُورَةُ المُمۡتَحنَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 14,
      name: "سُورَةُ الصَّفِّ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 11,
      name: "سُورَةُ الجُمُعَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 11,
      name: "سُورَةُ المُنَافِقُونَ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 18,
      name: "سُورَةُ التَّغَابُنِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 12,
      name: "سُورَةُ الطَّلَاقِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 12,
      name: "سُورَةُ التَّحۡرِيمِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 30,
      name: "سُورَةُ المُلۡكِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 52,
      name: "سُورَةُ القَلَمِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 52,
      name: "سُورَةُ الحَاقَّةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 44,
      name: "سُورَةُ المَعَارِجِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 28,
      name: "سُورَةُ نُوحٍ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 28,
      name: "سُورَةُ الجِنِّ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 20,
      name: "سُورَةُ المُزَّمِّلِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 56,
      name: "سُورَةُ المُدَّثِّرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 40,
      name: "سُورَةُ القِيَامَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 31,
      name: "سُورَةُ الإِنسَانِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 50,
      name: "سُورَةُ المُرۡسَلَاتِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 40,
      name: "سُورَةُ النَّبَإِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 46,
      name: "سُورَةُ النَّازِعَاتِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 42,
      name: "سُورَةُ عَبَسَ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 29,
      name: "سُورَةُ التَّكۡوِيرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 19,
      name: "سُورَةُ الانفِطَارِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 36,
      name: "سُورَةُ المُطَفِّفِينَ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 25,
      name: "سُورَةُ الانشِقَاقِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 22,
      name: "سُورَةُ البُرُوجِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 17,
      name: "سُورَةُ الطَّارِقِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 19,
      name: "سُورَةُ الأَعۡلَىٰ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 26,
      name: "سُورَةُ الغَاشِيَةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 30,
      name: "سُورَةُ الفَجۡرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 20,
      name: "سُورَةُ البَلَدِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 15,
      name: "سُورَةُ الشَّمۡسِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 21,
      name: "سُورَةُ اللَّيۡلِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 11,
      name: "سُورَةُ الضُّحَىٰ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 8,
      name: "سُورَةُ الشَّرۡحِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 8,
      name: "سُورَةُ التِّينِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 19,
      name: "سُورَةُ العَلَقِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 5,
      name: "سُورَةُ القَدۡرِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 8,
      name: "سُورَةُ البَيِّنَةِ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 8,
      name: "سُورَةُ الزَّلۡزَلَةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 11,
      name: "سُورَةُ العَادِيَاتِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 11,
      name: "سُورَةُ القَارِعَةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 8,
      name: "سُورَةُ التَّكَاثُرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 3,
      name: "سُورَةُ العَصۡرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 9,
      name: "سُورَةُ الهُمَزَةِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 5,
      name: "سُورَةُ الفِيلِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 4,
      name: "سُورَةُ قُرَيۡشٍ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 7,
      name: "سُورَةُ المَاعُونِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 3,
      name: "سُورَةُ الكَوۡثَرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 6,
      name: "سُورَةُ الكَافِرُونَ",
    },
    {
      revelationType: "Medinan",
      numberOfAyahs: 3,
      name: "سُورَةُ النَّصۡرِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 5,
      name: "سُورَةُ المَسَدِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 4,
      name: "سُورَةُ الإِخۡلَاصِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 5,
      name: "سُورَةُ الفَلَقِ",
    },
    {
      revelationType: "Meccan",
      numberOfAyahs: 6,
      name: "سُورَةُ النَّاسِ",
    },
  ];

  // let arr = [];
  // useEffect(() => {
  //   axios.get("https://api.alquran.cloud/v1/surah").then((surah) => {
  //     surah.data.data.forEach((ele) => {
  //       arr.push({
  //         revelationType: ele.revelationType,
  //         numberOfAyahs: ele.numberOfAyahs,
  //         name: ele.name,
  //       });
  //     });

  //     console.log(arr);
  //   });
  // }, []);

  return (
    <>
      <NavLink
        to={`/quran/read`}
        className="w-60 max-[400px]:w-52 shadow-lg border-1 border-slate-300 rounded-lg p-3 grid place-content-center text-2xl font-medium m-auto duration-300 hover:border-main hover:border-1 hover:text-sidebar active:scale-90"
      >
        المصحف كامل
      </NavLink>
      <div className="flex flex-wrap justify-center gap-7 m-auto">
        {quranSuras.map((ele, i) => {
          return (
            <NavLink
              key={i}
              to={`/quran/chsSura/${i + 1}`}
              className="showSurah w-60 max-[400px]:w-52 relative shadow-lg border-2 border-quranBtn rounded-lg p-3 grid place-content-center text-2xl font-medium duration-300 hover:border-main hover:border-1 hover:text-sidebar active:scale-90"
            >
              {ele.name}
              <span className="absolute bg-zinc-600 text-white text-center py-1 px-2 rounded-lg left-1/2 bottom-full w-4/5 origin-bottom">
                {ele.revelationType === "Meccan" ? "مكية" : "مدنية"} آياتها{" "}
                {ele.numberOfAyahs}
              </span>
            </NavLink>
          );
        })}
      </div>
    </>
  );
}

export default QuranChs;
