import { useEffect } from "react";
import MainBtn from "../comp/MainBtn";

const src = "https://peco-2.github.io/islamic/listen-quran/";

let lisetnArr = [
  {
    name: "حاتم فريد",
    img: `${src}hatem.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/hatem",
  },
  {
    name: "ياسر الدوسري",
    img: `${src}yasser.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/yasser",
  },
  {
    name: "أحمد بن علي العجمي",
    img: `${src}ajm.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/ajm",
  },
  {
    name: "فارس عباد",
    img: `${src}frs_a.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/frs_a",
  },
  {
    name: "ماهر المعيقلي",
    img: `${src}maher.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/maher",
  },
  {
    name: "ناصر القطامي",
    img: `${src}qtm.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/qtm",
  },
  {
    name: "عبد الباسط عبد الصمد",
    img: `${src}basit_mjwd.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/basit_mjwd",
  },
  {
    name: "علي جابر",
    img: `${src}a_jbr.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/a_jbr",
  },
  {
    name: "خالد الجليل",
    img: `${src}jleel.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/jleel",
  },
  {
    name: "عبدالرحمن السديس",
    img: `${src}sds.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/sds",
  },
  {
    name: "إدريس أبكر",
    img: `${src}abkr.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/abkr",
  },
  {
    name: "محمد اللحيدان",
    img: `${src}lhdan.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/lhdan",
  },
  {
    name: "محمد صديق المنشاوي",
    img: `${src}minsh.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/minsh",
  },
  {
    name: "مشاري راشد العفاسي",
    img: `${src}afs.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/afs",
  },
  {
    name: "عبدالرحمن العوسي",
    img: `${src}aloosi.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/aloosi",
  },
  {
    name: "مصطفى إسماعيل",
    img: `${src}mustafa.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/mustafa",
  },
  {
    name: "علي الحذيفي",
    img: `${src}hthfi.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/hthfi",
  },
  {
    name: "سعود ابراهيم شريم",
    img: `${src}shur.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/shur",
  },
  {
    name: "بندر بليله",
    img: `${src}balilah.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/balilah",
  },
  {
    name: "عبدالله خياط",
    img: `${src}kyat.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/kyat",
  },
  {
    name: "عبدالله عواد الجهني",
    img: `${src}jhn.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/jhn",
  },
  {
    name: "محمد أيوب",
    img: `${src}ayyub.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/ayyub",
  },
  {
    name: "منصور السالمي",
    img: `${src}mansor.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/mansor",
  },
  {
    name: "أحمد النفيس",
    img: `${src}nufais.jpg`,
    type: "حفص عن عاصم",
    length: 114,
    link: "/quran/listen/nufais",
  },
  {
    name: "هزاع البلوشي",
    img: `${src}hazza.jpg`,
    type: "حفص عن عاصم",
    length: 76,
    link: "/quran/listen/hazza",
  },
  {
    name: "سلمان العتيبي",
    img: `${src}salman.jpg`,
    type: "حفص عن عاصم",
    length: 61,
    link: "/quran/listen/salman",
  },
  {
    name: "خالد الغامدي",
    img: `${src}ghamdi.jpg`,
    type: "حفص عن عاصم",
    length: 33,
    link: "/quran/listen/ghamdi",
  },
  {
    name: "صالح آل طالب",
    img: `${src}tlb.jpg`,
    type: "حفص عن عاصم",
    length: 32,
    link: "/quran/listen/tlb",
  },
];
function QuranListen() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 h-screen flex gap-5";
  }, []);
  return (
    <div className="overflow-scroll w-full h-full p-1">
      <table className="quran-table bg-white rounded-lg p-2 w-full">
        <thead>
          <tr className="border-2 border-transparent border-b-border">
            <td className="text-2xl text-main text-center font-bold p-3">
              إسم القارئ
            </td>
            <td className="text-2xl text-main text-center font-bold p-3">
              عدد السور المتوفرة
            </td>
            <td className="text-2xl text-main text-center font-bold p-3">
              نوع الرواية
            </td>
            <td className="text-2xl text-main text-center font-bold p-3">
              الإستماع
            </td>
          </tr>
        </thead>
        <tbody>
          {lisetnArr.map(function (e, i) {
            return (
              <tr key={i} className="h-20 duration-300">
                <td className="text-2xl text-center font-semibold w-96">
                  <div className="relative pr-7">
                    {e.name}
                    <img
                      src={e.img}
                      className="w-16 h-16 object-cover rounded-full absolute top-1/2 right-2 -translate-y-1/2"
                      alt={e.name}
                    />
                  </div>
                </td>
                <td className="text-2xl text-center font-semibold p-3">
                  {e.length}
                </td>
                <td className="text-2xl text-center font-semibold p-3">
                  {e.type}
                </td>
                <td className="text-2xl text-center font-semibold p-3">
                  <MainBtn href={e.link} text="الإستماع" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default QuranListen;
