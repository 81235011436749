import React from "react";
import { NavLink } from "react-router-dom";

const MainBtn = (props) => {
  return (
    <NavLink
      to={props.href}
      className="main-btn block text-white rounded-md py-2 px-3 font-bold transition-all duration-500 text-center text-lg active:scale-90 m-auto"
      download={props.download && props.download}
      target={props.target}
    >
      <p>{props.text}</p>
    </NavLink>
  );
};

export default MainBtn;
