import { useLocation } from "react-router-dom";
import { obj } from "../App";
import { useEffect, useState } from "react";

function Audio() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 h-screen flex gap-5";
  }, []);

  const location = useLocation().pathname;
  let [srcName, setSrcName] = useState("");
  let [srcNum, setSrcNum] = useState("");

  let surahName = [
    "سُورَةُ ٱلْفَاتِحَةِ",
    "سُورَةُ البَقَرَةِ",
    "سُورَةُ آلِ عِمۡرَانَ",
    "سُورَةُ النِّسَاءِ",
    "سُورَةُ المَائـِدَةِ",
    "سُورَةُ الأَنۡعَامِ",
    "سُورَةُ الأَعۡرَافِ",
    "سُورَةُ الأَنفَالِ",
    "سُورَةُ التَّوۡبَةِ",
    "سُورَةُ يُونُسَ",
    "سُورَةُ هُودٍ",
    "سُورَةُ يُوسُفَ",
    "سُورَةُ الرَّعۡدِ",
    "سُورَةُ إِبۡرَاهِيمَ",
    "سُورَةُ الحِجۡرِ",
    "سُورَةُ النَّحۡلِ",
    "سُورَةُ الإِسۡرَاءِ",
    "سُورَةُ الكَهۡفِ",
    "سُورَةُ مَرۡيَمَ",
    "سُورَةُ طه",
    "سُورَةُ الأَنبِيَاءِ",
    "سُورَةُ الحَجِّ",
    "سُورَةُ المُؤۡمِنُونَ",
    "سُورَةُ النُّورِ",
    "سُورَةُ الفُرۡقَانِ",
    "سُورَةُ الشُّعَرَاءِ",
    "سُورَةُ النَّمۡلِ",
    "سُورَةُ القَصَصِ",
    "سُورَةُ العَنكَبُوتِ",
    "سُورَةُ الرُّومِ",
    "سُورَةُ لُقۡمَانَ",
    "سُورَةُ السَّجۡدَةِ",
    "سُورَةُ الأَحۡزَابِ",
    "سُورَةُ سَبَإٍ",
    "سُورَةُ فَاطِرٍ",
    "سُورَةُ يسٓ",
    "سُورَةُ الصَّافَّاتِ",
    "سُورَةُ صٓ",
    "سُورَةُ الزُّمَرِ",
    "سُورَةُ غَافِرٍ",
    "سُورَةُ فُصِّلَتۡ",
    "سُورَةُ الشُّورَىٰ",
    "سُورَةُ الزُّخۡرُفِ",
    "سُورَةُ الدُّخَانِ",
    "سُورَةُ الجَاثِيَةِ",
    "سُورَةُ الأَحۡقَافِ",
    "سُورَةُ مُحَمَّدٍ",
    "سُورَةُ الفَتۡحِ",
    "سُورَةُ الحُجُرَاتِ",
    "سُورَةُ قٓ",
    "سُورَةُ الذَّارِيَاتِ",
    "سُورَةُ الطُّورِ",
    "سُورَةُ النَّجۡمِ",
    "سُورَةُ القَمَرِ",
    "سُورَةُ الرَّحۡمَٰن",
    "سُورَةُ الوَاقِعَةِ",
    "سُورَةُ الحَدِيدِ",
    "سُورَةُ المُجَادلَةِ",
    "سُورَةُ الحَشۡرِ",
    "سُورَةُ المُمۡتَحنَةِ",
    "سُورَةُ الصَّفِّ",
    "سُورَةُ الجُمُعَةِ",
    "سُورَةُ المُنَافِقُونَ",
    "سُورَةُ التَّغَابُنِ",
    "سُورَةُ الطَّلَاقِ",
    "سُورَةُ التَّحۡرِيمِ",
    "سُورَةُ المُلۡكِ",
    "سُورَةُ القَلَمِ",
    "سُورَةُ الحَاقَّةِ",
    "سُورَةُ المَعَارِجِ",
    "سُورَةُ نُوحٍ",
    "سُورَةُ الجِنِّ",
    "سُورَةُ المُزَّمِّلِ",
    "سُورَةُ المُدَّثِّرِ",
    "سُورَةُ القِيَامَةِ",
    "سُورَةُ الإِنسَانِ",
    "سُورَةُ المُرۡسَلَاتِ",
    "سُورَةُ النَّبَإِ",
    "سُورَةُ النَّازِعَاتِ",
    "سُورَةُ عَبَسَ",
    "سُورَةُ التَّكۡوِيرِ",
    "سُورَةُ الانفِطَارِ",
    "سُورَةُ المُطَفِّفِينَ",
    "سُورَةُ الانشِقَاقِ",
    "سُورَةُ البُرُوجِ",
    "سُورَةُ الطَّارِقِ",
    "سُورَةُ الأَعۡلَىٰ",
    "سُورَةُ الغَاشِيَةِ",
    "سُورَةُ الفَجۡرِ",
    "سُورَةُ البَلَدِ",
    "سُورَةُ الشَّمۡسِ",
    "سُورَةُ اللَّيۡلِ",
    "سُورَةُ الضُّحَىٰ",
    "سُورَةُ الشَّرۡحِ",
    "سُورَةُ التِّينِ",
    "سُورَةُ العَلَقِ",
    "سُورَةُ القَدۡرِ",
    "سُورَةُ البَيِّنَةِ",
    "سُورَةُ الزَّلۡزَلَةِ",
    "سُورَةُ العَادِيَاتِ",
    "سُورَةُ القَارِعَةِ",
    "سُورَةُ التَّكَاثُرِ",
    "سُورَةُ العَصۡرِ",
    "سُورَةُ الهُمَزَةِ",
    "سُورَةُ الفِيلِ",
    "سُورَةُ قُرَيۡشٍ",
    "سُورَةُ المَاعُونِ",
    "سُورَةُ الكَوۡثَرِ",
    "سُورَةُ الكَافِرُونَ",
    "سُورَةُ النَّصۡرِ",
    "سُورَةُ المَسَدِ",
    "سُورَةُ الإِخۡلَاصِ",
    "سُورَةُ الفَلَقِ",
    "سُورَةُ النَّاسِ",
  ];

  let objNames = {
    qtm: "ناصر القطامي",
    abkr: "إدريس أبكر",
    minsh: "محمد صديق المنشاوي",
    aloosi: "عبدالرحمن العوسي",
    frs_a: "فارس عباد",
    sds: "عبدالرحمن السديس",
    lhdan: "محمد اللحيدان",
    afs: "مشاري راشد العفاسي",
    mustafa: "مصطفى إسماعيل",
    jleel: "خالد الجليل",
    ajm: "أحمد بن علي العجمي",
    hatem: "حاتم فريد",
    yasser: "ياسر الدوسري",
    a_jbr: "علي جابر",
    hazza: "هزاع البلوشي",
    maher: "ماهر المعيقلي",
    basit_mjwd: "عبد الباسط عبد الصمد",
    hthfi: "علي الحذيفي",
    shur: "سعود ابراهيم شريم",
    balilah: "بندر بليله",
    kyat: "عبدالله خياط",
    jhn: "عبدالله عواد الجهني",
    tlb: "صالح آل طالب",
    ghamdi: "خالد الغامدي",
  };

  useEffect(() => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      const name = location.replace("/quran/listen/", "").replace(/\/\d+/, "");
      if (Object.keys(obj)[i] === name) setSrcName(Object.keys(obj)[i]);
    }

    setSrcNum(location.match(/\d+/)[0]);
  }, []);
  return (
    <div className="center mainBoxs flex flex-col gap-6 overflow-scroll w-full h-full duration-300">
      <div className="relative before:absolute before:bottom-0 before:right-0 before:w-full before:h-full before:opacity-60 before:bg-gradient-to-t before:from-main before:rounded-3xl">
        {srcName !== "" && (
          <img
            src={`https://peco-2.github.io/islamic/audio-page/${srcName}.png`}
            alt={srcName}
            className="object-cover top-0 left-0 h-[720px] max-h-[65vh] w-full rounded-3xl"
          />
        )}
        <p className="absolute bottom-6 right-4 text-2xl md:text-4xl text-white font-bold">
          {surahName[srcNum - 1]} بصوت القارئ {objNames[srcName]}
        </p>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center gap-2">
          <button
            className="w-12 h-12 bg-opacity-80 bg-white rounded-full text-xl font-medium"
            onClick={() => (document.getElementById("audio").currentTime += 10)}
          >
            +10
          </button>
          <button
            className="w-20 h-20 bg-opacity-80 bg-white rounded-full flex justify-center items-center"
            onClick={function () {
              let icon = document.getElementById("playIcon");
              let audio = document.getElementById("audio");

              icon.classList.contains("fa-play") ? audio.play() : audio.pause();

              icon.classList.toggle("fa-pause");
              icon.classList.toggle("fa-play");
            }}
          >
            <i
              id="playIcon"
              className="fa-solid fa-play text-main text-4xl"
            ></i>
          </button>
          <button
            className="w-12 h-12 bg-opacity-80 bg-white rounded-full text-xl font-medium"
            onClick={function () {
              document.getElementById("audio").currentTime -= 10;
            }}
          >
            -10
          </button>
        </div>
        <div className="icons flex items-end gap-4 absolute left-4 bottom-4">
          <i
            className="fa-solid fa-repeat relative text-white text-2xl cursor-pointer after:w-8 after:h-1 after:rounded-lg after:absolute after:top-1/2 after:-translate-y-1/2 after:left-1/2 after:-translate-x-1/2 after:rotate-45 after:bg-white"
            onClick={function (e) {
              let audio = document.getElementById("audio");

              if (audio.getAttribute("loop") !== null) {
                e.currentTarget.classList.add(
                  "after:w-8",
                  "after:h-1",
                  "after:rounded-lg",
                  "after:absolute",
                  "after:top-1/2",
                  "after:-translate-y-1/2",
                  "after:left-1/2",
                  "after:-translate-x-1/2",
                  "after:rotate-45",
                  "after:bg-white"
                );
                audio.removeAttribute("loop");
              } else {
                e.currentTarget.classList.remove(
                  "after:w-8",
                  "after:h-1",
                  "after:rounded-lg",
                  "after:absolute",
                  "after:top-1/2",
                  "after:-translate-y-1/2",
                  "after:left-1/2",
                  "after:-translate-x-1/2",
                  "after:rotate-45",
                  "after:bg-white"
                );
                audio.setAttribute("loop", true);
              }
            }}
          ></i>
          <div className="volume flex justify-center items-center flex-col-reverse gap-3 rounded-lg">
            <i className="fa-solid fa-volume-high text-white text-2xl"></i>
            <input
              type="range"
              min="0"
              max="100"
              className="accent-main bg-white duration-300"
              onInput={(e) => {
                document.getElementById("audio").volume = +e.target.value / 100;
              }}
            />
          </div>
        </div>
        {srcName.length !== 0 && (
          <audio
            id="audio"
            src={`https://server${obj[srcName][1]}.mp3quran.net/${srcName}/${
              srcNum < 10 ? `00${srcNum}` : srcNum < 100 ? `0${srcNum}` : srcNum
            }.mp3`}
            className="hidden"
          ></audio>
        )}
      </div>
      <div className="p-2.5 bg-white rounded-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="font-extrabold text-2xl">باقي القراءات</h2>
          <div className="flex items-center gap-4">
            {+srcNum !== 1 && (
              <a href={+srcNum - 1} className="text-main">
                <i
                  className="fa-solid fa-circle-play text-main cursor-pointer w-10 h-10 justify-center items-center"
                  style={{ display: "flex", fontSize: "40px" }}
                ></i>
              </a>
            )}
            {+srcNum !== 114 && (
              <a href={+srcNum + 1} className="text-main">
                <i
                  className="fa-solid fa-circle-play fa-rotate-180 text-main cursor-pointer w-10 h-10 justify-center items-center"
                  style={{ display: "flex", fontSize: "40px" }}
                ></i>
              </a>
            )}
          </div>
        </div>
        {srcName !== "" && (
          <ul className="space-y-8">
            {obj[srcName][0].map((e, i) => {
              return (
                <li
                  key={i}
                  className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-5 relative before:w-3/4 before:h-[1.5px] before:bg-zinc-200 before:rounded-lg before:absolute before:-bottom-4 before:left-1/2 before:-translate-x-1/2"
                >
                  <div className="flex gap-3 items-center font-medium text-2xl">
                    {e < 10 ? `00${e}` : e < 100 ? `0${e}` : e}
                    <img
                      src={`https://peco-2.github.io/islamic/listen-quran/${srcName}.jpg`}
                      alt={srcName}
                      className="w-14 h-14 object-cover rounded-full"
                    />
                    {surahName[e - 1]}
                  </div>
                  <div className="flex gap-9 md:gap-3 w-full md:w-auto justify-center">
                    <a href={e}>
                      <i
                        className="fa-solid fa-circle-play fa-rotate-180 text-main w-10 h-10 justify-center items-center text-4xl cursor-pointer"
                        style={{ display: "flex", fontSize: "40px" }}
                      ></i>
                    </a>
                    <button
                      className="text-white bg-main rounded-full w-10 h-10 justify-center items-center text-2xl cursor-pointer flex"
                      onClick={(el) => {
                        const element = el.currentTarget;

                        navigator.clipboard
                          .writeText(
                            `${window.location.host}/quran/listen/${srcName}/${e}`
                          )
                          .then(() => (element.textContent = "تم!ّ"))
                          .catch(() => (element.textContent = "فشل!ّ"));
                        setTimeout(() => {
                          element.innerHTML = `<i class='fa-solid fa-share-nodes'></i>`;
                        }, 1500);
                        navigator.clipboard.writeText(
                          `${window.location.host}/quran/listen/${srcName}/${e}`
                        );
                      }}
                    >
                      <i className="fa-solid fa-share-nodes"></i>
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Audio;
