import React from "react";
import MainQuranTitle from "./MainQuranTitle";
import Num from "./Num";

let prays = [
  {
    id: "quran",
    title: "ادعية القران",
    content: [
      {
        id: 1,
        source: "الفاتحة (6-7)",
        text: "اهْدِنَا الصِّرَاطَ الْمُسْتَقِيمَ * صِرَاطَ الَّذِينَ أَنْعَمْتَ عَلَيْهِمْ غَيْرِ الْمَغْضُوبِ عَلَيْهِمْ وَلَا الضَّالِّينَ",
      },
      {
        id: 2,
        source: "البقرة (126)",
        text: "وَإِذْ قَالَ إِبْرَاهِيمُ رَبِّ اجْعَلْ هَٰذَا بَلَدًا آمِنًا وَارْزُقْ أَهْلَهُ مِنَ الثَّمَرَاتِ مَنْ آمَنَ مِنْهُمْ بِاللَّهِ وَالْيَوْمِ الْآخِرِ ۖ قَالَ وَمَنْ كَفَرَ فَأُمَتِّعُهُ قَلِيلًا ثُمَّ أَضْطَرُّهُ إِلَىٰ عَذَابِ النَّارِ ۖ وَبِئْسَ الْمَصِيرُ",
      },
      {
        id: 3,
        source: "البقرة (127 - 128)",
        text: "وَإِذْ يَرْفَعُ إِبْرَاهِيمُ الْقَوَاعِدَ مِنَ الْبَيْتِ وَإِسْمَاعِيلُ رَبَّنَا تَقَبَّلْ مِنَّا ۖ إِنَّكَ أَنْتَ السَّمِيعُ الْعَلِيمُ * رَبَّنَا وَاجْعَلْنَا مُسْلِمَيْنِ لَكَ وَمِنْ ذُرِّيَّتِنَا أُمَّةً مُسْلِمَةً لَكَ وَأَرِنَا مَنَاسِكَنَا وَتُبْ عَلَيْنَا ۖ إِنَّكَ أَنْتَ التَّوَّابُ الرَّحِيمُ",
      },
      {
        id: 4,
        source: "البقرة (129)",
        text: "رَبَّنَا وَابْعَثْ فِيهِمْ رَسُولًا مِنْهُمْ يَتْلُو عَلَيْهِمْ آيَاتِكَ وَيُعَلِّمُهُمُ الْكِتَابَ وَالْحِكْمَةَ وَيُزَكِّيهِمْ ۚ إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ",
      },
      {
        id: 5,
        source: "البقرة (201)",
        text: "وَمِنْهُمْ مَنْ يَقُولُ رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ",
      },
      {
        id: 6,
        source: "البقرة (250)",
        text: "وَلَمَّا بَرَزُوا لِجَالُوتَ وَجُنُودِهِ قَالُوا رَبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
      },
      {
        id: 7,
        source: "البقرة (286)",
        text: "رَبَّنَا لَا تُؤَاخِذْنَا إِنْ نَسِينَا أَوْ أَخْطَأْنَا ۚ رَبَّنَا وَلَا تَحْمِلْ عَلَيْنَا إِصْرًا كَمَا حَمَلْتَهُ عَلَى الَّذِينَ مِنْ قَبْلِنَا ۚ رَبَّنَا وَلَا تُحَمِّلْنَا مَا لَا طَاقَةَ لَنَا بِهِ ۖ وَاعْفُ عَنَّا وَاغْفِرْ لَنَا وَارْحَمْنَا ۚ أَنْتَ مَوْلَانَا فَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
      },
      {
        id: 8,
        source: "آل عمران (8 - 9)",
        text: "رَبَّنَا لَا تُزِغْ قُلُوبَنَا بَعْدَ إِذْ هَدَيْتَنَا وَهَبْ لَنَا مِنْ لَدُنْكَ رَحْمَةً ۚ إِنَّكَ أَنْتَ الْوَهَّابُ * رَبَّنَا إِنَّكَ جَامِعُ النَّاسِ لِيَوْمٍ لَا رَيْبَ فِيهِ ۚ إِنَّ اللَّهَ لَا يُخْلِفُ الْمِيعَادَ",
      },
      {
        id: 9,
        source: "آل عمران (16)",
        text: "الَّذِينَ يَقُولُونَ رَبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّارِ",
      },
      {
        id: 10,
        source: "آل عمران (26 - 27)",
        text: "قُلِ اللَّهُمَّ مَالِكَ الْمُلْكِ تُؤْتِي الْمُلْكَ مَنْ تَشَاءُ وَتَنْزِعُ الْمُلْكَ مِمَّنْ تَشَاءُ وَتُعِزُّ مَنْ تَشَاءُ وَتُذِلُّ مَنْ تَشَاءُ ۖ بِيَدِكَ الْخَيْرُ ۖ إِنَّكَ عَلَىٰ كُلِّ شَيْءٍ قَدِيرٌ * تُولِجُ اللَّيْلَ فِي النَّهَارِ وَتُولِجُ النَّهَارَ فِي اللَّيْلِ ۖ وَتُخْرِجُ الْحَيَّ مِنَ الْمَيِّتِ وَتُخْرِجُ الْمَيِّتَ مِنَ الْحَيِّ ۖ وَتَرْزُقُ مَنْ تَشَاءُ بِغَيْرِ حِسَابٍ",
      },
      {
        id: 11,
        source: "آل عمران (35)",
        text: "إِذْ قَالَتِ امْرَأَتُ عِمْرَانَ رَبِّ إِنِّي نَذَرْتُ لَكَ مَا فِي بَطْنِي مُحَرَّرًا فَتَقَبَّلْ مِنِّي ۖ إِنَّكَ أَنْتَ السَّمِيعُ الْعَلِيمُ",
      },
      {
        id: 12,
        source: "آل عمران (38)",
        text: "هُنَالِكَ دَعَا زَكَرِيَّا رَبَّهُ ۖ قَالَ رَبِّ هَبْ لِي مِنْ لَدُنْكَ ذُرِّيَّةً طَيِّبَةً ۖ إِنَّكَ سَمِيعُ الدُّعَاءِ",
      },
      {
        id: 13,
        source: "آل عمران (53)",
        text: "رَبَّنَا آمَنَّا بِمَا أَنْزَلْتَ وَاتَّبَعْنَا الرَّسُولَ فَاكْتُبْنَا مَعَ الشَّاهِدِينَ",
      },
      {
        id: 14,
        source: "آل عمران (147)",
        text: "وَمَا كَانَ قَوْلَهُمْ إِلَّا أَنْ قَالُوا رَبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
      },
      {
        id: 15,
        source: "آل عمران (191 - 194)",
        text: "رَبَّنَا مَا خَلَقْتَ هَٰذَا بَاطِلًا سُبْحَانَكَ فَقِنَا عَذَابَ النَّارِ * رَبَّنَا إِنَّكَ مَنْ تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ ۖ وَمَا لِلظَّالِمِينَ مِنْ أَنْصَارٍ * رَبَّنَا إِنَّنَا سَمِعْنَا مُنَادِيًا يُنَادِي لِلْإِيمَانِ أَنْ آمِنُوا بِرَبِّكُمْ فَآمَنَّا ۚ رَبَّنَا فَاغْفِرْ لَنَا ذُنُوبَنَا وَكَفِّرْ عَنَّا سَيِّئَاتِنَا وَتَوَفَّنَا مَعَ الْأَبْرَارِ * رَبَّنَا وَآتِنَا مَا وَعَدْتَنَا عَلَىٰ رُسُلِكَ وَلَا تُخْزِنَا يَوْمَ الْقِيَامَةِ ۗ إِنَّكَ لَا تُخْلِفُ الْمِيعَادَ",
      },
      {
        id: 16,
        source: "النساء (75)",
        text: "رَبَّنَا أَخْرِجْنَا مِنْ هَٰذِهِ الْقَرْيَةِ الظَّالِمِ أَهْلُهَا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ وَلِيًّا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ نَصِيرًا",
      },
      {
        id: 17,
        source: "المائدة (83)",
        text: "يَقُولُونَ رَبَّنَا آمَنَّا فَاكْتُبْنَا مَعَ الشَّاهِدِينَ",
      },
      {
        id: 18,
        source: "المائدة (114)",
        text: "قَالَ عِيسَى ابْنُ مَرْيَمَ اللَّهُمَّ رَبَّنَا أَنْزِلْ عَلَيْنَا مَائِدَةً مِنَ السَّمَاءِ تَكُونُ لَنَا عِيدًا لِأَوَّلِنَا وَآخِرِنَا وَآيَةً مِنْكَ ۖ وَارْزُقْنَا وَأَنْتَ خَيْرُ الرَّازِقِينَ",
      },
      {
        id: 19,
        source: "الأعراف (23)",
        text: "قَالَا رَبَّنَا ظَلَمْنَا أَنْفُسَنَا وَإِنْ لَمْ تَغْفِرْ لَنَا وَتَرْحَمْنَا لَنَكُونَنَّ مِنَ الْخَاسِرِينَ",
      },
      {
        id: 20,
        source: "الأعراف (47)",
        text: "قَالُوا۟ رَبَّنَا لَا تَجۡعَلۡنَا مَعَ ٱلۡقَوۡمِ ٱلظَّـٰلِمِینَ",
      },
      {
        id: 21,
        source: "الأعراف (89)",
        text: "رَبَّنَا ٱفۡتَحۡ بَیۡنَنَا وَبَیۡنَ قَوۡمِنَا بِٱلۡحَقِّ وَأَنتَ خَیۡرُ ٱلۡفَـٰتِحِینَ",
      },
      {
        id: 22,
        source: "الأعراف (126)",
        text: "رَبَّنَاۤ أَفۡرِغۡ عَلَیۡنَا صَبۡرࣰا وَتَوَفَّنَا مُسۡلِمِینَ",
      },
      {
        id: 23,
        source: "الأعراف (151)",
        text: "قَالَ رَبِّ ٱغۡفِرۡ لِی وَلِأَخِی وَأَدۡخِلۡنَا فِی رَحۡمَتِكَۖ وَأَنتَ أَرۡحَمُ ٱلرَّ ٰ⁠حِمِینَ",
      },
      {
        id: 24,
        source: "الأعراف (155)",
        text: "أَنتَ وَلِیُّنَا فَٱغۡفِرۡ لَنَا وَٱرۡحَمۡنَاۖ وَأَنتَ خَیۡرُ ٱلۡغَـٰفِرِینَ",
      },
      {
        id: 25,
        source: "الأعراف (156)",
        text: "وَٱكۡتُبۡ لَنَا فِی هَـٰذِهِ ٱلدُّنۡیَا حَسَنَةࣰ وَفِی ٱلۡـَٔاخِرَةِ إِنَّا هُدۡنَاۤ إِلَیۡكَۚ",
      },
      {
        id: 26,
        source: "يونس (85 - 86)",
        text: "فَقَالُوا۟ عَلَى ٱللَّهِ تَوَكَّلۡنَا رَبَّنَا لَا تَجۡعَلۡنَا فِتۡنَةࣰ لِّلۡقَوۡمِ ٱلظَّـٰلِمِینَ * وَنَجِّنَا بِرَحۡمَتِكَ مِنَ ٱلۡقَوۡمِ ٱلۡكَـٰفِرِینَ",
      },
      {
        id: 27,
        source: "هود (47)",
        text: "قَالَ رَبِّ إِنِّیۤ أَعُوذُ بِكَ أَنۡ أَسۡـَٔلَكَ مَا لَیۡسَ لِی بِهِۦ عِلۡمࣱۖ وَإِلَّا تَغۡفِرۡ لِی وَتَرۡحَمۡنِیۤ أَكُن مِّنَ ٱلۡخَـٰسِرِینَ",
      },
      {
        id: 28,
        source: "يوسف (101)",
        text: "فَاطِرَ ٱلسَّمَـٰوَ ٰ⁠تِ وَٱلۡأَرۡضِ أَنتَ وَلِیِّۦ فِی ٱلدُّنۡیَا وَٱلۡـَٔاخِرَةِۖ تَوَفَّنِی مُسۡلِمࣰا وَأَلۡحِقۡنِی بِٱلصَّـٰلِحِینَ",
      },
      {
        id: 29,
        source: "إبراهيم (38)",
        text: "رَبَّنَا إِنَّكَ تَعْلَمُ مَا نُخْفِي وَمَا نُعْلِنُ ۗ وَمَا يَخْفَىٰ عَلَى اللَّهِ مِن شَيْءٍ فِي الْأَرْضِ وَلَا فِي السَّمَاءِ",
      },
      {
        id: 30,
        source: "إبراهيم (40 - 41)",
        text: "رَبِّ ٱجۡعَلۡنِی مُقِیمَ ٱلصَّلَوٰةِ وَمِن ذُرِّیَّتِیۚ رَبَّنَا وَتَقَبَّلۡ دُعَاۤءِ * رَبَّنَا ٱغۡفِرۡ لِی وَلِوَ ٰ⁠لِدَیَّ وَلِلۡمُؤۡمِنِینَ یَوۡمَ یَقُومُ ٱلۡحِسَابُ",
      },
      {
        id: 31,
        source: "الإسراء (24)",
        text: "رَّبِّ ٱرۡحَمۡهُمَا كَمَا رَبَّیَانِی صَغِیرࣰا",
      },
      {
        id: 32,
        source: "الإسراء (80)",
        text: "رَّبِّ أَدۡخِلۡنِی مُدۡخَلَ صِدۡقࣲ وَأَخۡرِجۡنِی مُخۡرَجَ صِدۡقࣲ وَٱجۡعَل لِّی مِن لَّدُنكَ سُلۡطَـٰنࣰا نَّصِیرࣰا",
      },
      {
        id: 33,
        source: "الكهف (10)",
        text: "رَبَّنَاۤ ءَاتِنَا مِن لَّدُنكَ رَحۡمَةࣰ وَهَیِّئۡ لَنَا مِنۡ أَمۡرِنَا رَشَدࣰا",
      },
      {
        id: 34,
        source: "مريم (4)",
        text: "رَبِّ إِنِّی وَهَنَ ٱلۡعَظۡمُ مِنِّی وَٱشۡتَعَلَ ٱلرَّأۡسُ شَیۡبࣰا وَلَمۡ أَكُنۢ بِدُعَاۤىِٕكَ رَبِّ شَقِیࣰّا",
      },
      {
        id: 35,
        source: "طه (25 - 28)",
        text: "قَالَ رَبِّ ٱشۡرَحۡ لِی صَدۡرِی * وَیَسِّرۡ لِیۤ أَمۡرِی * وَٱحۡلُلۡ عُقۡدَةࣰ مِّن لِّسَانِی * یَفۡقَهُوا۟ قَوۡلِی",
      },
      {
        id: 36,
        source: "طه (114)",
        text: "رَّبِّ زِدۡنِی عِلۡمࣰا",
      },
      {
        id: 37,
        source: "الأنبياء (83)",
        text: "أَنِّی مَسَّنِیَ ٱلضُّرُّ وَأَنتَ أَرۡحَمُ ٱلرَّ ٰ⁠حِمِینَ",
      },
      {
        id: 38,
        source: "الأنبياء (87)",
        text: "لَاۤ إِلَـٰهَ إِلَّاۤ أَنتَ سُبۡحَـٰنَكَ إِنِّی كُنتُ مِنَ ٱلظَّـٰلِمِینَ",
      },
      {
        id: 39,
        source: "الأنبياء (89)",
        text: "رَبِّ لَا تَذَرۡنِی فَرۡدࣰا وَأَنتَ خَیۡرُ ٱلۡوَ ٰ⁠رِثِینَ",
      },
      {
        id: 40,
        source: "المؤمنون (29)",
        text: "رَّبِّ أَنزِلۡنِی مُنزَلࣰا مُّبَارَكࣰا وَأَنتَ خَیۡرُ ٱلۡمُنزِلِینَ",
      },
      {
        id: 41,
        source: "المؤمنون (39)",
        text: "رَبِّ ٱنصُرۡنِی بِمَا كَذَّبُونِ",
      },
      {
        id: 42,
        source: "المؤمنون (93 - 94)",
        text: "قُل رَّبِّ إِمَّا تُرِیَنِّی مَا یُوعَدُونَ * رَبِّ فَلَا تَجۡعَلۡنِی فِی ٱلۡقَوۡمِ ٱلظَّـٰلِمِینَ",
      },
      {
        id: 43,
        source: "المؤمنون (97 - 98)",
        text: "رَّبِّ أَعُوذُ بِكَ مِنۡ هَمَزَ ٰ⁠تِ ٱلشَّیَـٰطِینِ * وَأَعُوذُ بِكَ رَبِّ أَن یَحۡضُرُونِ",
      },
      {
        id: 44,
        source: "المؤمنون (118)",
        text: "رَّبِّ ٱغۡفِرۡ وَٱرۡحَمۡ وَأَنتَ خَیۡرُ ٱلرَّ ٰ⁠حِمِینَ",
      },
      {
        id: 45,
        source: "الفرقان (65 - 66)",
        text: "رَبَّنَا ٱصۡرِفۡ عَنَّا عَذَابَ جَهَنَّمَۖ إِنَّ عَذَابَهَا كَانَ غَرَامًا * إِنَّهَا سَاۤءَتۡ مُسۡتَقَرࣰّا وَمُقَامࣰا",
      },
      {
        id: 46,
        source: "الفرقان (74)",
        text: "رَبَّنَا هَبۡ لَنَا مِنۡ أَزۡوَ ٰ⁠جِنَا وَذُرِّیَّـٰتِنَا قُرَّةَ أَعۡیُنࣲ وَٱجۡعَلۡنَا لِلۡمُتَّقِینَ إِمَامًا",
      },
      {
        id: 47,
        source: "الشعراء (83 - 89)",
        text: "رَبِّ هَبۡ لِی حُكۡمࣰا وَأَلۡحِقۡنِی بِٱلصَّـٰلِحِینَ * وَٱجۡعَل لِّی لِسَانَ صِدۡقࣲ فِی ٱلۡـَٔاخِرِینَ * وَٱجۡعَلۡنِی مِن وَرَثَةِ جَنَّةِ ٱلنَّعِیمِ * وَٱغۡفِرۡ لِأَبِیۤ إِنَّهُۥ كَانَ مِنَ ٱلضَّاۤلِّینَ * وَلَا تُخۡزِنِی یَوۡمَ یُبۡعَثُونَ * یَوۡمَ لَا یَنفَعُ مَالࣱ وَلَا بَنُونَ * إِلَّا مَنۡ أَتَى ٱللَّهَ بِقَلۡبࣲ سَلِیمࣲ",
      },
      {
        id: 48,
        source: "الشعراء (169)",
        text: "رَبِّ نَجِّنِی وَأَهۡلِی مِمَّا یَعۡمَلُونَ",
      },
      {
        id: 50,
        source: "النمل (19)",
        text: "رَبِّ أَوۡزِعۡنِیۤ أَنۡ أَشۡكُرَ نِعۡمَتَكَ ٱلَّتِیۤ أَنۡعَمۡتَ عَلَیَّ وَعَلَىٰ وَ ٰ⁠لِدَیَّ وَأَنۡ أَعۡمَلَ صَـٰلِحࣰا تَرۡضَىٰهُ وَأَدۡخِلۡنِی بِرَحۡمَتِكَ فِی عِبَادِكَ ٱلصَّـٰلِحِینَ",
      },
      {
        id: 51,
        source: "النمل (44)",
        text: "رَبِّ إِنِّی ظَلَمۡتُ نَفۡسِی",
      },
      {
        id: 52,
        source: "القصص (16)",
        text: "رَبِّ إِنِّی ظَلَمۡتُ نَفۡسِی فَٱغۡفِرۡ لِی",
      },
      {
        id: 53,
        source: "القصص (21)",
        text: "رَبِّ نَجِّنِی مِنَ ٱلۡقَوۡمِ ٱلظَّـٰلِمِینَ",
      },
      {
        id: 54,
        source: "القصص (24)",
        text: "رَبِّ إِنِّی لِمَاۤ أَنزَلۡتَ إِلَیَّ مِنۡ خَیۡرࣲ فَقِیرࣱ",
      },
      {
        id: 55,
        source: "العنكبوت (24)",
        text: "رَبِّ ٱنصُرۡنِی عَلَى ٱلۡقَوۡمِ ٱلۡمُفۡسِدِینَ",
      },
      {
        id: 56,
        source: "الصافات (24)",
        text: "رَبِّ هَبۡ لِی مِنَ ٱلصَّـٰلِحِینَ",
      },
      {
        id: 57,
        source: "غافر (7 - 9)",
        text: "رَبَّنَا وَسِعۡتَ كُلَّ شَیۡءࣲ رَّحۡمَةࣰ وَعِلۡمࣰا فَٱغۡفِرۡ لِلَّذِینَ تَابُوا۟ وَٱتَّبَعُوا۟ سَبِیلَكَ وَقِهِمۡ عَذَابَ ٱلۡجَحِیمِ * رَبَّنَا وَأَدۡخِلۡهُمۡ جَنَّـٰتِ عَدۡنٍ ٱلَّتِی وَعَدتَّهُمۡ وَمَن صَلَحَ مِنۡ ءَابَاۤىِٕهِمۡ وَأَزۡوَ ٰ⁠جِهِمۡ وَذُرِّیَّـٰتِهِمۡۚ إِنَّكَ أَنتَ ٱلۡعَزِیزُ ٱلۡحَكِیمُ * وَقِهِمُ ٱلسَّیِّـَٔاتِۚ وَمَن تَقِ ٱلسَّیِّـَٔاتِ یَوۡمَىِٕذࣲ فَقَدۡ رَحِمۡتَهُۥۚ وَذَ ٰ⁠لِكَ هُوَ ٱلۡفَوۡزُ ٱلۡعَظِیمُ",
      },
      {
        id: 58,
        source: "الدخان (12)",
        text: "رَّبَّنَا ٱكۡشِفۡ عَنَّا ٱلۡعَذَابَ إِنَّا مُؤۡمِنُونَ",
      },
      {
        id: 59,
        source: "الأحقاف (15)",
        text: "رَبِّ أَوۡزِعۡنِیۤ أَنۡ أَشۡكُرَ نِعۡمَتَكَ ٱلَّتِیۤ أَنۡعَمۡتَ عَلَیَّ وَعَلَىٰ وَ ٰ⁠لِدَیَّ وَأَنۡ أَعۡمَلَ صَـٰلِحࣰا تَرۡضَىٰهُ وَأَصۡلِحۡ لِی فِی ذُرِّیَّتِیۤۖ إِنِّی تُبۡتُ إِلَیۡكَ وَإِنِّی مِنَ ٱلۡمُسۡلِمِینَ",
      },
      {
        id: 60,
        source: "الحشر (10)",
        text: "رَبَّنَا ٱغۡفِرۡ لَنَا وَلِإِخۡوَ ٰ⁠نِنَا ٱلَّذِینَ سَبَقُونَا بِٱلۡإِیمَـٰنِ وَلَا تَجۡعَلۡ فِی قُلُوبِنَا غِلࣰّا لِّلَّذِینَ ءَامَنُوا۟ رَبَّنَاۤ إِنَّكَ رَءُوفࣱ رَّحِیمٌ",
      },
      {
        id: 61,
        source: "الممتحنة (4 - 5)",
        text: "رَّبَّنَا عَلَیۡكَ تَوَكَّلۡنَا وَإِلَیۡكَ أَنَبۡنَا وَإِلَیۡكَ ٱلۡمَصِیرُ * رَبَّنَا لَا تَجۡعَلۡنَا فِتۡنَةࣰ لِّلَّذِینَ كَفَرُوا۟ وَٱغۡفِرۡ لَنَا رَبَّنَاۤۖ إِنَّكَ أَنتَ ٱلۡعَزِیزُ ٱلۡحَكِیمُ",
      },
      {
        id: 62,
        source: "التحريم (8)",
        text: "رَبَّنَاۤ أَتۡمِمۡ لَنَا نُورَنَا وَٱغۡفِرۡ لَنَاۤۖ إِنَّكَ عَلَىٰ كُلِّ شَیۡءࣲ قَدِیرࣱ",
      },
      {
        id: 63,
        source: "التحريم (11)",
        text: "رَبِّ ٱبۡنِ لِی عِندَكَ بَیۡتࣰا فِی ٱلۡجَنَّةِ",
      },
      {
        id: 64,
        source: "نوح (26 - 28)",
        text: "رَّبِّ لَا تَذَرۡ عَلَى ٱلۡأَرۡضِ مِنَ ٱلۡكَـٰفِرِینَ دَیَّارًا * إِنَّكَ إِن تَذَرۡهُمۡ یُضِلُّوا۟ عِبَادَكَ وَلَا یَلِدُوۤا۟ إِلَّا فَاجِرࣰا كَفَّارࣰا * رَّبِّ ٱغۡفِرۡ لِی وَلِوَ ٰ⁠لِدَیَّ وَلِمَن دَخَلَ بَیۡتِیَ مُؤۡمِنࣰا وَلِلۡمُؤۡمِنِینَ وَٱلۡمُؤۡمِنَـٰتِۖ وَلَا تَزِدِ ٱلظَّـٰلِمِینَ إِلَّا تَبَارَۢا",
      },
    ],
  },
  {
    id: "prayer",
    title: "ادعية الصلاة",
    content: [
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 744 صفحة 471 - 472
          </>
        ),
        text: "اللَّهُمَّ بَاعِدْ بَيْنِي وَبَيْنَ خَطَايَايَ ، كَمَا بَاعَدْتَ بَيْنَ المَشْرِقِ وَالمَغْرِبِ ، اللَّهُمَّ نَقِّنِي مِنَ الخَطَايَا كَمَا يُنَقَّى الثَّوْبُ الأَبْيَضُ مِنَ الدَّنَسِ ، اللَّهُمَّ اغْسِلْ خَطَايَايَ بِالْمَاءِ وَالثَّلْجِ وَالبَرَدِ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 1120 صفحة 632
          </>
        ),
        text: "اللَّهُمَّ لكَ الحَمْدُ أنْتَ قَيِّمُ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، ولَكَ الحَمْدُ لكَ مُلْكُ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، ولَكَ الحَمْدُ أنْتَ نُورُ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، ولَكَ الحَمْدُ أنْتَ مَلِكُ السَّمَوَاتِ والأرْضِ، ولَكَ الحَمْدُ أنْتَ الحَقُّ ووَعْدُكَ الحَقُّ، ولِقَاؤُكَ حَقٌّ، وقَوْلُكَ حَقٌّ، والجَنَّةُ حَقٌّ، والنَّارُ حَقٌّ، والنَّبِيُّونَ حَقٌّ، ومُحَمَّدٌ صَلَّى اللهُ عليه وسلَّمَ حَقٌّ، والسَّاعَةُ حَقٌّ، اللَّهُمَّ لكَ أسْلَمْتُ، وبِكَ آمَنْتُ، وعَلَيْكَ تَوَكَّلْتُ، وإلَيْكَ أنَبْتُ، وبِكَ خَاصَمْتُ، وإلَيْكَ حَاكَمْتُ، فَاغْفِرْ لي ما قَدَّمْتُ وما أخَّرْتُ، وما أسْرَرْتُ وما أعْلَنْتُ، أنْتَ المُقَدِّمُ، وأَنْتَ المُؤَخِّرُ، لا إلَهَ إلَّا أنْتَ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 2397 صفحة 1139
          </>
        ),
        text: "اللَّهُمَّ إنِّي أعُوذُ بكَ مِنَ المَأْثَمِ والمَغْرَمِ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 4293 صفحة 1885
          </>
        ),
        text: "سُبْحانَكَ اللَّهُمَّ رَبَّنا وبِحَمْدِكَ، اللَّهُمَّ اغْفِرْ لِي",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 6326 صفحة 2807
          </>
        ),
        text: "اللَّهُمَّ إنِّي ظَلَمْتُ نَفْسِي ظُلْمًا كَثِيرًا، ولَا يَغْفِرُ الذُّنُوبَ إلَّا أنْتَ، فَاغْفِرْ لي مَغْفِرَةً مِن عِندِكَ، وارْحَمْنِي، إنَّكَ أنْتَ الغَفُورُ الرَّحِيمُ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 6390 صفحة 2829
          </>
        ),
        text: "اللَّهُمَّ إنِّي أعُوذُ بكَ مِنَ الجُبْنِ، وأَعُوذُ بكَ مِنَ البُخْلِ، وأَعُوذُ بكَ مِن أنْ أُرَدَّ إلى أرْذَلِ العُمُرِ، وأَعُوذُ بكَ مِن فِتْنَةِ الدُّنْيَا، وعَذَابِ القَبْرِ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 7442 صفحة 3302
          </>
        ),
        text: "اللَّهُمَّ رَبَّنَا لكَ الحَمْدُ أنْتَ قَيِّمُ السَّمَوَاتِ والأرْضِ، ولَكَ الحَمْدُ أنْتَ رَبُّ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، ولَكَ الحَمْدُ أنْتَ نُورُ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، أنْتَ الحَقُّ، وقَوْلُكَ الحَقُّ، ووَعْدُكَ الحَقُّ، ولِقَاؤُكَ الحَقُّ، والجَنَّةُ حَقٌّ، والنَّارُ حَقٌّ، والسَّاعَةُ حَقٌّ، اللَّهُمَّ لكَ أسْلَمْتُ، وبِكَ آمَنْتُ، وعَلَيْكَ تَوَكَّلْتُ، وإلَيْكَ خَاصَمْتُ، وبِكَ حَاكَمْتُ، فَاغْفِرْ لي ما قَدَّمْتُ وما أخَّرْتُ، وأَسْرَرْتُ وأَعْلَنْتُ، وما أنْتَ أعْلَمُ به مِنِّي، لا إلَهَ إلَّا أنْتَ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 476 صفحة 347
          </>
        ),
        text: "اللَّهُمَّ لكَ الحَمْدُ مِلْءُ السَّماءِ، ومِلْءُ الأرْضِ، ومِلْءُ ما شِئْتَ مِن شيءٍ بَعْدُ اللَّهُمَّ طَهِّرْنِي بالثَّلْجِ والْبَرَدِ، والْماءِ البارِدِ اللَّهُمَّ طَهِّرْنِي مِنَ الذُّنُوبِ والْخَطايا، كما يُنَقَّى الثَّوْبُ الأبْيَضُ مِنَ الوَسَخِ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 483 صفحة 351
          </>
        ),
        text: "اللَّهُمَّ اغْفِرْ لي ذَنْبِي كُلَّهُ دِقَّهُ، وجِلَّهُ، وأَوَّلَهُ وآخِرَهُ وعلانِيَتَهُ وسِرَّهُ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 486 صفحة 353
          </>
        ),
        text: "اللَّهُمَّ أعُوذُ برِضَاكَ مِن سَخَطِكَ، وبِمُعَافَاتِكَ مِن عُقُوبَتِكَ، وأَعُوذُ بكَ مِنْكَ لا أُحْصِي ثَنَاءً عَلَيْكَ أنْتَ كما أثْنَيْتَ علَى نَفْسِكَ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 588 صفحة 413
          </>
        ),
        text: "اللَّهُمَّ إنِّي أَعُوذُ بِكَ مِن عَذَابِ جَهَنَّمَ، وَمِنْ عَذَابِ القَبْرِ، وَمِنْ فِتْنَةِ المَحْيَا وَالْمَمَاتِ، وَمِنْ شَرِّ فِتْنَةِ المَسِيحِ الدَّجَّالِ",
      },
      {
        source: (
          <>
            صحيح أب داوود
            <br />
            الحديث 760 صفحة 217
          </>
        ),
        text: "وَجَّهْتُ وَجْهِيَ لِلَّذِي فَطَرَ السَّمَوَاتِ وَالْأَرْضَ حَنِيفًا مُسْلِمًا وَمَا أَنَا مِنَ الْمُشْرِكِينَ، إِنَّ صَلَاتِي وَنُسُكِي وَمَحْيَايَ وَمَمَاتِي لِلَّهِ رَبِّ الْعَالَمِينَ، لَاشَرِيكَ لَهُ وَبِذَلِكَ أُمِرْتُ وَأَنَا أَوَّلُ الْمُسْلِمِينَ، اللَّهُمَّ أَنْتَ الْمَلِكُ لَا إِلَهَ لِي إِلَّا أَنْتَ، أَنْتَ رَبِّي وَأَنَا عَبْدُكَ، ظَلَمْتُ نَفْسِي وَاعْتَرَفْتُ بِذَنْبِي فَاغْفِرْ لِي ذُنُوبِي جَمِيعًا إِنَّهُ لَا يَغْفِرُ الذُّنُوبَ إِلَّا أَنْتَ، وَاهْدِنِي لِأَحْسَنِ الْأَخْلَاقِ لَا يَهْدِي لِأَحْسَنِهَا إِلَّا أَنْتَ، وَاصْرِفْ عَنِّي سَيِّئَهَا لَا يَصْرِفُ سَيِّئَهَا إِلَّا أَنْتَ، لَبَّيْكَ وَسَعْدَيْكَ وَالْخَيْرُ كُلُّهُ فِي يَدَيْكَ، وَالشَّرُّ لَيْسَ إِلَيْكَ أَنَا بِكَ وَإِلَيْكَ، تَبَارَكْتَ وَتَعَالَيْتَ، أَسْتَغْفِرُكَ وَأَتُوبُ إِلَيْكَ",
      },
    ],
  },
  {
    id: "haj",
    title: "ادعية العشرة الأوائل و الحج",
    content: [
      {
        source: "متفق عليه في الصحيحين",
        text: "لَبَّيْكَ اللّهُمَّ لَبَّيْكَ، لَبَّيْكَ لا شَرِيكَ لكَ لَبَّيْكَ، إنَّ الحَمْدَ وَالنِّعْمَةَ لَكَ وَالْمُلْكَ، لا شَرِيكَ لكَ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 3293 صفحة 1520
          </>
        ),
        text: "لا إلَهَ إلّا اللَّهُ وحدَهُ لا شريكَ لَهُ، لَهُ الملكُ ولَهُ الحمدُ وَهوَ على كلِّ شَيءٍ قديرٌ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 4116 صفحة 1831
          </>
        ),
        text: "الله أكبر الله أكبر الله أكبر لا إلهَ إلا اللهُ وحدَهُ لا شريك لهُ لهُ الملكُ ولهُ الحمدُ وهو على كلِّ شيٍء قديرٌ آيبونَ تائبونَ عابدونَ ساجدونَ لربنا حامدونَ صدق اللهُ وعدَهُ ونصرَ عبدَهُ وهزم الأحزابَ وحدَهُ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 6306 صفحة 2798
          </>
        ),
        text: "اللَّهُمَّ أنْتَ رَبِّي لا إلَهَ إلَّا أنْتَ، خَلَقْتَنِي وأنا عَبْدُكَ، وأنا علَى عَهْدِكَ ووَعْدِكَ ما اسْتَطَعْتُ، أعُوذُ بكَ مِن شَرِّ ما صَنَعْتُ، أبُوءُ لكَ بنِعْمَتِكَ عَلَيَّ، وأَبُوءُ لكَ بذَنْبِي فاغْفِرْ لِي، فإنَّه لا يَغْفِرُ الذُّنُوبَ إلَّا أنْتَ",
      },
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 6398 صفحة 2832
          </>
        ),
        text: "رَبِّ اغْفِرْ لي خَطِيئَتي وجَهْلِي، وإسْرَافِي في أمْرِي كُلِّهِ، وما أنْتَ أعْلَمُ به مِنِّي، اللَّهُمَّ اغْفِرْ لي خَطَايَايَ، وعَمْدِي وجَهْلِي وهَزْلِي، وكُلُّ ذلكَ عِندِي، اللَّهُمَّ اغْفِرْ لي ما قَدَّمْتُ وما أخَّرْتُ، وما أسْرَرْتُ وما أعْلَنْتُ، أنْتَ المُقَدِّمُ وأَنْتَ المُؤَخِّرُ، وأَنْتَ علَى كُلِّ شيءٍ قَدِيرٌ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 483 صفحة 351
          </>
        ),
        text: "اللَّهُمَّ اغْفِرْ لي ذَنْبِي كُلَّهُ دِقَّهُ، وجِلَّهُ، وأَوَّلَهُ وآخِرَهُ وعَلانِيَتَهُ وسِرَّهُ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 2720 صفحة 2088
          </>
        ),
        text: "اللَّهُمَّ أَصْلِحْ لي دِينِي الذي هو عِصْمَةُ أَمْرِي، وَأَصْلِحْ لي دُنْيَايَ الَّتي فِيهَا معاشِي، وَأَصْلِحْ لي آخِرَتي الَّتي فِيهَا معادِي، وَاجْعَلِ الحَيَاةَ زِيَادَةً لي في كُلِّ خَيْرٍ، وَاجْعَلِ المَوْتَ رَاحَةً لي مِن كُلِّ شَرٍّ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 2721 صفحة 2088
          </>
        ),
        text: "اللَّهُمَّ إنِّي أَسْأَلُكَ الهُدَى وَالتُّقَى، وَالْعَفَافَ وَالْغِنَى",
      },
      {
        source: (
          <>
            صحيح سنن أبي داوود
            <br />
            الحديث 5090
          </>
        ),
        text: "اللَّهمَّ عافِني في بَدَني، اللَّهمَّ عافِني في سَمْعي، اللَّهمَّ عافِني في بَصَري، لا إلهَ إلَّا أنتَ",
      },
      {
        source: (
          <>
            صحيح سنن أبي داوود
            <br />
            زيادة في الحديث 5090 إسناده صحيح
          </>
        ),
        text: "اللَّهمَّ إنِّي أَعوذُ بكَ منَ الكُفرِ والفَقرِ، اللَّهمَّ إنِّي أَعوذُ بِكَ مِن عَذابِ القَبرِ، لا إلهَ إلَّا أنتَ",
      },
    ],
  },
  {
    id: "ramadan",
    title: "ادعية الصوم و رمضان",
    content: [
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 1120 صفحة 632
          </>
        ),
        text: "اللَّهُمَّ لكَ الحَمْدُ أنْتَ قَيِّمُ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، ولَكَ الحَمْدُ لكَ مُلْكُ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، ولَكَ الحَمْدُ أنْتَ نُورُ السَّمَوَاتِ والأرْضِ ومَن فِيهِنَّ، ولَكَ الحَمْدُ أنْتَ مَلِكُ السَّمَوَاتِ والأرْضِ، ولَكَ الحَمْدُ أنْتَ الحَقُّ ووَعْدُكَ الحَقُّ، ولِقَاؤُكَ حَقٌّ، وقَوْلُكَ حَقٌّ، والجَنَّةُ حَقٌّ، والنَّارُ حَقٌّ، والنَّبِيُّونَ حَقٌّ، ومُحَمَّدٌ صَلَّى اللهُ عليه وسلَّمَ حَقٌّ، والسَّاعَةُ حَقٌّ، اللَّهُمَّ لكَ أسْلَمْتُ، وبِكَ آمَنْتُ، وعَلَيْكَ تَوَكَّلْتُ، وإلَيْكَ أنَبْتُ، وبِكَ خَاصَمْتُ، وإلَيْكَ حَاكَمْتُ، فَاغْفِرْ لي ما قَدَّمْتُ وما أخَّرْتُ، وما أسْرَرْتُ وما أعْلَنْتُ، أنْتَ المُقَدِّمُ، وأَنْتَ المُؤَخِّرُ، لا إلَهَ إلَّا أنْتَ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 486 صفحة 353
          </>
        ),
        text: "اللهُمَّ أَعُوذُ بِرِضَاكَ مِنْ سَخَطِكَ ، وَبِمُعَافَاتِكَ مِنْ عُقُوبَتِكَ ، وَأَعُوذُ بِكَ مِنْكَ لَا أُحْصِي ثَنَاءً عَلَيْكَ أَنْتَ كَمَا أَثْنَيْتَ عَلَى نَفْسِكَ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 763 صفحة 526
          </>
        ),
        text: "اللهمَّ اجعلْ في قلبي نورًا، وفي لساني نورًا، وفي بصري نورًا، وفي سمعي نورًا، وعنْ يميني نورًا، وعنْ يساري نورًا، ومنْ فوقي نورًا، ومنْ تحتي نورًا، ومنْ أمامي نورًا، ومنْ خلفي نورًا، واجعلْ لي في نفسي نورًا، وأَعْظِمْ لي نورًا",
      },
      {
        source: (
          <>
            صحيح أبي داوود
            <br />
            الحديث 2357
          </>
        ),
        text: "ذَهَبَ الظَّمأُ وابتلَّتِ العُروقُ وثبَتَ الأجرُ إن شاءَ اللَّهُ",
      },
      {
        source: "مبتكر",
        text: "اللهم اجعل صيامي فيهِ بالشّكر والقبول على ما ترضاه ويرضاهُ الرّسول محكمةً فروعه بالأصول، بحقّ سيّدنا محمدٍ وآله الطّاهرين، والحمد لله ربّ العالمين يارب في خير شهر وخير أيام تفضلت فيها على عبادك أن تهديني هداية لا ضلال بعدها، وأن تثبتني على الطاعة وتكفيني بعزتك وقدرتك التي لا تنضب ولا تحرم منها أحدًا شر أربع مغريات للنفوس الدنيا والهوى والشيطان والنفس أمارة السوء، اللهم ارزقني حسن العود والرجوع",
      },
    ],
  },
  {
    id: "elm",
    title: "أدعية العلم",
    content: [
      {
        source: "طه (114)",
        text: "وَقُل رَبِّ زِدني عِلمًا",
      },
      {
        source: "الشعراء (83)",
        text: "رَبِّ هَبۡ لِی حُكۡمࣰا وَأَلۡحِقۡنِی بِٱلصَّـٰلِحِینَ",
      },
      {
        source: "مبتكر",
        text: "اللهمّ يا من قلت وقولك الحق: (ادْعُونِي أَسْتَجِبْ لَكُمْ)[غافر - 6] اجعل دعائي مُستجاب، وارزقني القدرة على التحصيل، وعلمني ما ينفعني، وانفعني بما علمتني، وزدني علماً وعملاً وفِقهاً وإخلاصاً في الـدين",
      },
      {
        source: "مبتكر",
        text: "اللهم باعد بيني وبين وساوس الشيطان، ولا تجعلني أشعر بتكبر أو تفاخر بما أمتلكه من معارف وعلوم، فإنك نهيت عن التكبر وإني لا أحب هذا الخلق الوضيع",
      },
      {
        source: "من حديث ضعيف",
        text: "اللهم انفعني بما علمتني، وعلمني ما ينفعني",
      },
    ],
  },
  {
    id: "travel",
    title: "أدعية السفر",
    content: [
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 2995 صفحة 1405
          </>
        ),
        text: "الله أكبر الله أكبر الله أكبر، لا إلَهَ إلَّا اللَّهُ، وحْدَهُ لا شَرِيكَ له، له المُلْكُ وله الحَمْدُ، وهو علَى كُلِّ شيءٍ قَدِيرٌ، آيِبُونَ تَائِبُونَ عَابِدُونَ سَاجِدُونَ لِرَبِّنَا حَامِدُونَ، صَدَقَ اللَّهُ وعْدَهُ، ونَصَرَ عَبْدَهُ، وهَزَمَ الأحْزَابَ وحْدَهُ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 1342 صفحة 979
          </>
        ),
        text: "الله أكبر الله أكبر الله أكبر . سُبْحَانَ الَّذِي سَخَّرَ لَنَا هَذَا وَمَا كُنَّا لَهُ مُقْرِنِينَ. وَإِنَّا إِلَى رَبِّنَا لَمُنْقَلِبُون. اللَّهُمَّ إِنَّا نَسْأَلُكَ فِي سَفَرِنَا هَذَا الْبِرَّ وَالتَّقْوَى. وَمِنَ الْعَمَلِ مَا تَرْضَى. اللهم! هون علينا سفرنا هذا. واطوعنا بُعْدَهُ. اللَّهُمَّ أَنْتَ الصَّاحِبُ فِي السَّفَرِ. وَالْخَلِيفَةُ فِي الْأَهْلِ. اللَّهُمَّ إِنِّي أَعُوذُ بِكَ مِنْ وَعْثَاءِ السَّفَرِ، وَكَآبَةِ الْمَنْظَرِ، وَسُوءِ الْمُنْقَلَبِ، فِي المال والأهل",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 2708 صفحة 2082
          </>
        ),
        text: "أَعُوذُ بكَلِمَاتِ اللهِ التَّامَّاتِ مِن شَرِّ ما خَلَقَ",
      },
      {
        source: "مبتكر",
        text: "للهمَّ إنّ لي مسافراً لا أرى حياتي من بعده فاحفظه لي بعينك التي لا تنام، اللهمَّ إنّي أستودعتك إيّاه فاجعله في ودائعك التي لا تضيع، اللهمَّ احفظه من أي مكروهٍ يصيبه ومن كلّ شرٍ وضررٍ، اللهمَّ احفظه من الأسقام والأمراض",
      },
      {
        source: "مبتكر",
        text: "اللهمَّ احفظني واصرف عني كلّ سوءٍ، اللهمَّ احفظني في سفري وفي حِلّي وترحالي، اللهمَّ احفظ كلّ مسافرٍ حتى يصل لوجهته وسهّل دربه يا رب العالمين",
      },
    ],
  },
  {
    id: "different",
    title: "أدعية منوعية",
    content: [
      {
        source: (
          <>
            صحيح البخاري
            <br />
            الحديث 6368 صفحة 2821
          </>
        ),
        text: "اللَّهُمَّ إنِّي أعُوذُ بكَ مِنَ الكَسَلِ والهَرَمِ، والمَأْثَمِ والمَغْرَمِ، ومِنْ فِتْنَةِ القَبْرِ، وعَذابِ القَبْرِ، ومِنْ فِتْنَةِ النَّارِ وعَذابِ النَّارِ، ومِنْ شَرِّ فِتْنَةِ الغِنَى، وأَعُوذُ بكَ مِن فِتْنَةِ الفَقْرِ، وأَعُوذُ بكَ مِن فِتْنَةِ المَسِيحِ الدَّجَّالِ، اللَّهُمَّ اغْسِلْ عَنِّي خَطايايَ بماءِ الثَّلْجِ والبَرَدِ، ونَقِّ قَلْبِي مِنَ الخَطايا كما نَقَّيْتَ الثَّوْبَ الأبْيَضَ مِنَ الدَّنَسِ، وباعِدْ بَيْنِي وبيْنَ خَطايايَ كما باعَدْتَ بيْنَ المَشْرِقِ والمَغْرِبِ",
      },
      {
        source: (
          <>
            صحيح مسلم
            <br />
            الحديث 2202 صفحة 1729
          </>
        ),
        text: "أَعُوذُ باللَّهِ وَقُدْرَتِهِ مِن شَرِّ ما أَجِدُ وَأُحَاذِرُ",
      },
      {
        source: "مبتكر",
        text: "ربّ اشرح لي صدري، ويسّر لي أمري، واحــلل عقـدةً من لساني، يفقهوا قولي، بسم الله، اللهم لا سهل إلا ما جعلته سهلا، وأنت تجعل الحزن إذا شئت سهلا يا أرحم الراحمين. لا إله إلا أنت سبحانك إنّي كنت من الظالمين، يا حي يا قيوم، برحمتك أستغيث، ربّ إنّي مسني الضر وأنت أرحم الراحمين",
      },
      {
        source: "مبتكر",
        text: "اللهم أعني على قضاءِ ديني و سَدِّ حاجتي والحمد لك سبحانك على ما أعطيتني حمداً كثيراً",
      },
      {
        source: "مبتكر",
        text: "اللهم إن حوائجي كثيرة وضعفي كبير وأنت الرب القدير اللهم ارحم ضعفي وأجب سؤالي واقضِ حاجتي وسددّ ديني",
      },
    ],
  },
];

function PrayBoxs() {
  return prays.map(function (e, i) {
    return (
      <div key={e.id} id={e.id}>
        <MainQuranTitle title={e.title} id={i + 1} />
        {e.content.map(function (el, ind) {
          return (
            <React.Fragment key={ind}>
              <div className="pray-container">
                <div className="pray-title flex items-center justify-between mt-1">
                  <div className="flex items-center flex-row gap-3">
                    <Num id={ind + 1} />
                    <span className="font-bold text-xl">{el.source}</span>
                  </div>
                  <button
                    className="copy-btn h-fit p-2 w-12 border-0 text-white rounded-md text-xl font-bold cursor-pointer duration-300 active:scale-90"
                    onClick={(e) => {
                      const btn = e.currentTarget;

                      navigator.clipboard
                        .writeText(el.text)
                        .then(() => (btn.textContent = "تم!ّ"))
                        .catch(() => (btn.textContent = "فشل!ّ"));
                      setTimeout(() => {
                        btn.innerHTML = `<i class="uil uil-copy"></i>`;
                      }, 1500);
                    }}
                  >
                    <i className="uil uil-copy"></i>
                  </button>
                </div>
                <div className="pray-content">
                  <p
                    className="text-3xl leading-loose font-medium text-center mt-2 mb-4"
                    style={{ fontFamily: "conv_original-hafs" }}
                  >
                    {el.text}
                  </p>
                </div>
              </div>
              {ind + 1 !== e.content.length && <hr />}
            </React.Fragment>
          );
        })}
      </div>
    );
  });
}

export default PrayBoxs;
