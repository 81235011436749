import MainBtn from "./MainBtn";

let khatabBoxs = [
  {
    name: "حاتم فريد",
    nameEN: "hatem",
    img: "listen-quran/hatem",
  },
  {
    name: "عثمان الخميس",
    nameEN: "othmanalkamees",
    img: "khatab/othmanalkamees",
  },
  {
    name: "ياسر الدوسري",
    nameEN: "yasser",
    img: "listen-quran/yasser",
  },
  {
    name: "ناصر القطامي",
    nameEN: "qtm",
    img: "listen-quran/qtm",
  },
  {
    name: "عبدالرحمن السديس",
    nameEN: "sds",
    img: "listen-quran/sds",
  },
  {
    name: "ماهر المعيقلي",
    nameEN: "maher",
    img: "listen-quran/maher",
  },
  {
    name: "صالح بن حميد",
    nameEN: "ibnhomaid",
    img: "khatab/ibnhomaid",
  },
];

let src = "https://peco-2.github.io/islamic/";

function KhatabBoxs() {
  return khatabBoxs.map((e, i) => {
    return (
      <div
        key={i}
        className="p-4 bg-white rounded-2xl flex flex-col gap-4 items-center shadow-box"
      >
        <div className="img">
          <img
            src={`${src + e.img}.jpg`}
            className="w-1/2 m-auto duration-300 rounded-2xl hover:rounded-[50%]"
            alt={e.name}
          />
        </div>
        <p className="text-xl md:text-2xl font-medium">خطب {e.name}</p>
        <MainBtn href={`/khatab/${e.nameEN}`} text="الإستماع" />
      </div>
    );
  });
}

export default KhatabBoxs;
