import Choose from "./Choose";

let chooseArr = [
  {
    id: 1,
    name: "أدعية القران",
    href: "/pray#quran",
  },
  {
    id: 2,
    name: "أدعية للصلاة",
    href: "/pray#prayer",
  },
  {
    id: 3,
    name: "العشرة الأوائل و الحج",
    href: "/pray#haj",
  },
  {
    id: 4,
    name: "أدعية الصوم و رمضان",
    href: "/pray#ramadan",
  },
  {
    id: 5,
    name: "أدعية العلم",
    href: "/pray#elm",
  },
  {
    id: 6,
    name: "أدعية السفر",
    href: "/pray#travel",
  },
  {
    id: 7,
    name: "أدعية منوعة",
    href: "/pray#different",
  },
];

function ChoosePray() {
  return chooseArr.map((e) => (
    <Choose key={e.id} id={e.id} name={e.name} href={e.href} />
  ));
}

export default ChoosePray;
