import ShowSurah from "../comp/ShowSurah";
import { useEffect } from "react";

function Surah() {
  useEffect(() => {
    document.getElementById("father").className =
      "content w-full py-4 px-5 pt-24 h-screen flex gap-5";
  }, []);

  const num = window.location.pathname.replace(/[^0-9]/g, "");
  return (
    <div
      className="center-listen bg-white rounded-md p-5 overflow-y-scroll w-full scroll-smooth"
      onScroll={(e) => {
        localStorage.setItem(`scroll${num}`, e.target.scrollTop);

        for (let prop in localStorage) {
          if (localStorage.getItem(prop)) {
            +localStorage.getItem(prop) === 0 && localStorage.removeItem(prop);
          }
        }
      }}
    >
      <ShowSurah num={num} />
    </div>
  );
}

export default Surah;
