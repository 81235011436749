import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

function Layout() {
  return (
    <>
      <Header />
      <div
        className="relative w-full py-4 px-5 pt-24 min-h-screen flex items-center gap-5"
        id="father"
        dir="rtl"
      >
        <Sidebar />
        <Outlet />
      </div>
    </>
  );
}

export default Layout;
